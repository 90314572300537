import { FieldType, NodeGraphDataFrameFieldNames } from '@grafana/data';

export const nodes = {
  fields: [
    {
      name: NodeGraphDataFrameFieldNames.id,
      type: FieldType.string,
      values: [
        '0',
        'candycon2000',
        'pantry-summary',
        'compute-splines-api',
        'send-notification',
        'popcorn-distributor',
        'caramel-gateway',
        'candy-distributor',
        'candy-query-frontend',
        'candy-ruler',
        'candy-querier',
        'candy-chocolate-querier',
        'candy-backend',
        'candy-read',
        'candy-write',
        'popcorn-backend',
        'popcorn-query-frontend',
        'proxy-reads',
        'proxy-gateway',
        'proxy-writes',
        'dentist',
        'fudge-proxy-gateway',
        'candy-admin-api',
        'candy-disposer',
        'candy-ingester',
        'candy-gateway',
        'candy-chocolate-write-proxy',
        'candy-store-gateway',
        'popcorn-querier',
        'candy-query-scheduler',
        'api',
        'controller',
        'gateway',
        'db',
        'marshmallow-popcorn-plugin',
        'eggnog-query',
        'licorice-ingester',
        'licorice-distributor',
        'licorice-gateway',
        'licorice-querier',
        'licorice-query-frontend',
        'licorice-ruler',
        'licorice-read',
        'licorice-write',
        'licorice-sugar-gateway',
        'licorice-query-scheduler',
        'popcorn-ingester',
        'popcorn-ruler',
        'peanutapi',
        'almondapi',
        'worker',
        'toffee-gateway',
        'marshmallow-gateway',
        'toffee-proxy-gateway',
        'mints-distributor',
        'mints-agent',
        'mints-ingester',
        'marshmallow-query-frontend',
        'marshmallow-ingester',
        'marshmallow-querier',
        'eating-scheduler',
        'calorie-generator',
        'candy-packaging-exporter',
        'candy-manager',
        'overeat-gateway',
        'wrappers-api',
        'overeat_api',
        'licorice-admin-api',
        'almond',
        'almondops-gateway',
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.title,
      config: {
        displayName: 'Service name',
      },
      type: FieldType.string,
      values: [
        '0',
        'candycon2000',
        'pantry-summary',
        'compute-splines-api',
        'send-notification',
        'popcorn-distributor',
        'caramel-gateway',
        'candy-distributor',
        'candy-query-frontend',
        'candy-ruler',
        'candy-querier',
        'candy-chocolate-querier',
        'candy-backend',
        'candy-read',
        'candy-write',
        'popcorn-backend',
        'popcorn-query-frontend',
        'proxy-reads',
        'proxy-gateway',
        'proxy-writes',
        'dentist',
        'fudge-proxy-gateway',
        'candy-admin-api',
        'candy-disposer',
        'candy-ingester',
        'candy-gateway',
        'candy-chocolate-write-proxy',
        'candy-store-gateway',
        'popcorn-querier',
        'candy-query-scheduler',
        'api',
        'controller',
        'gateway',
        'db',
        'marshmallow-popcorn-plugin',
        'eggnog-query',
        'licorice-ingester',
        'licorice-distributor',
        'licorice-gateway',
        'licorice-querier',
        'licorice-query-frontend',
        'licorice-ruler',
        'licorice-read',
        'licorice-write',
        'licorice-sugar-gateway',
        'licorice-query-scheduler',
        'popcorn-ingester',
        'popcorn-ruler',
        'peanutapi',
        'almondapi',
        'worker',
        'toffee-gateway',
        'marshmallow-gateway',
        'toffee-proxy-gateway',
        'mints-distributor',
        'mints-agent',
        'mints-ingester',
        'marshmallow-query-frontend',
        'marshmallow-ingester',
        'marshmallow-querier',
        'eating-scheduler',
        'calorie-generator',
        'candy-packaging-exporter',
        'candy-manager',
        'overeat-gateway',
        'wrappers-api',
        'overeat_api',
        'licorice-admin-api',
        'almond',
        'almondops-gateway',
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.mainStat,
      config: {
        unit: 'ms/r',
        displayName: 'Average response time',
      },
      type: FieldType.number,
      values: [
        0.3792332384891563,
        0.8672229016348518,
        null,
        16.20695699632165,
        39.928285334583585,
        9.655124202033475,
        21.05268555627574,
        5.867123115073844,
        2.4860418856893776,
        355.53214166441546,
        20.55178572103791,
        null,
        38.89817349596472,
        8.180751216025271,
        0.8054812870678301,
        40.77678203483556,
        85.482797395998,
        16.27026063145621,
        null,
        19.87661445917064,
        0.22942839760131845,
        null,
        null,
        null,
        0.5613453804178244,
        null,
        null,
        8.992145179555344,
        304.9330783211414,
        null,
        10.395531440216706,
        null,
        null,
        null,
        0.015141602590812384,
        null,
        2516.1181289425012,
        1.753552009081408,
        2.18723509171844,
        2042.6033859578174,
        35.584947433232884,
        12.650689593313736,
        11469.63275426663,
        10032.233577903402,
        2.7579124143530875,
        null,
        12.313124260662056,
        null,
        449.2619110960284,
        null,
        457.68439141665425,
        4.200223281332062,
        3.22412985358373,
        null,
        16.03316977154454,
        null,
        5.830408312271875,
        1460.8431543525896,
        6.379590630002257,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.secondaryStat,
      config: {
        unit: 'r/sec',
        displayName: 'Requests per second',
      },
      type: FieldType.number,
      values: [
        0,
        0,
        null,
        0,
        0,
        0,
        0,
        0,
        0.01,
        0,
        0.01,
        null,
        0,
        0,
        0.02,
        0,
        0,
        0,
        null,
        0,
        0,
        null,
        null,
        null,
        0.1,
        null,
        null,
        0,
        0,
        null,
        0,
        null,
        null,
        null,
        0,
        null,
        0.06,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        null,
        0,
        null,
        0,
        null,
        0,
        0,
        0,
        null,
        0,
        null,
        0,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: 'arc__success',
      config: {
        displayName: 'Success',
        color: {
          fixedColor: 'green',
          mode: 'fixed',
        },
      },
      type: FieldType.number,
      values: [
        1, 1, 1, 1, 0, 1, 0.999931271795046, 0.9999982854540072, 0.9999993106537873, 1, 0.9999988795927239, 1,
        0.9984939706365067, 0.9999936988458762, 0.9374731810143394, 0.9981691514900277, 1, 1, 1, 0.6918050349578433, 1,
        1, 1, 1, 0.9649904403274051, 1, 1, 0.996693264215713, 0.9990646675327494, 1, 0.989711501101388, 1, 1, 1, 1, 1,
        0.974376082211577, 1, 1, 0.9979501689527777, 0.9999554801847671, 0.7997517799066115, 0.998393334495106,
        0.8077280841753209, 0.9999889402832068, 1, 0.9267936098112096, 1, 0.9678190225122817, 1, 0.9664342727367723, 1,
        1, 1, 1, 1, 1, 0.49879813934581896, 0.8514429797914538, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
      ],
    },
    {
      name: 'arc__failed',
      config: {
        displayName: 'Failed',
        color: {
          fixedColor: 'red',
          mode: 'fixed',
        },
      },
      type: FieldType.number,
      values: [
        0, 0, 0, 0, 1, 0, 0.00006872820495400671, 0.0000017145459928594954, 6.893462126672767e-7, 0,
        0.000001120407275990985, 0, 0.001506029363493422, 0.000006301154123861364, 0.06252681898566055,
        0.0018308485099723893, 0, 0, 0, 0.30819496504215677, 0, 0, 0, 0, 0.03500955967259495, 0, 0,
        0.0033067357842869243, 0.0009353324672505954, 0, 0.01028849889861211, 0, 0, 0, 0, 0, 0.025623917788423094, 0, 0,
        0.002049831047222383, 0.00004451981523281711, 0.20024822009338844, 0.001606665504893849, 0.19227191582467903,
        0.000011059716793143872, 0, 0.07320639018879034, 0, 0.032180977487718344, 0, 0.03356572726322771, 0, 0, 0, 0, 0,
        0, 0.501201860654181, 0.14855702020854625, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
    },
  ],
  meta: { preferredVisualisationType: 'nodeGraph' as const },
  name: 'nodes',
};

export const edges = {
  fields: [
    {
      name: NodeGraphDataFrameFieldNames.id,
      type: FieldType.string,
      values: [
        'pantry-summary_candycon2000',
        'compute-splines-api_candycon2000',
        'compute-splines-api_send-notification',
        'caramel-gateway_popcorn-distributor',
        'caramel-gateway_candy-distributor',
        'caramel-gateway_candy-query-frontend',
        'caramel-gateway_candy-ruler',
        'caramel-gateway_caramel-gateway',
        'caramel-gateway_candy-querier',
        'caramel-gateway_candy-chocolate-querier',
        'caramel-gateway_candy-backend',
        'caramel-gateway_candy-read',
        'caramel-gateway_candy-write',
        'caramel-gateway_popcorn-backend',
        'caramel-gateway_popcorn-query-frontend',
        'proxy-gateway_proxy-reads',
        'proxy-gateway_proxy-writes',
        'proxy-reads_caramel-gateway',
        'proxy-writes_caramel-gateway',
        'fudge-proxy-gateway_dentist',
        'candy-admin-api_candy-distributor',
        'candy-backend_candy-read',
        'candy-backend_candy-write',
        'candy-disposer_candy-distributor',
        'candy-distributor_candy-ingester',
        'candy-distributor_candy-distributor',
        'candy-gateway_candy-admin-api',
        'candy-gateway_candy-distributor',
        'candy-gateway_candy-query-frontend',
        'candy-chocolate-write-proxy_caramel-gateway',
        'candy-ingester_candy-distributor',
        'candy-querier_candy-ingester',
        'candy-querier_candy-query-frontend',
        'candy-querier_candy-store-gateway',
        'candy-querier_candy-distributor',
        'candy-query-frontend_candy-querier',
        'candy-query-frontend_popcorn-querier',
        'candy-query-frontend_candy-distributor',
        'candy-query-scheduler_candy-distributor',
        'candy-read_candy-backend',
        'candy-read_candy-read',
        'candy-read_candy-write',
        'candy-read_popcorn-backend',
        'candy-ruler_candy-ruler',
        'candy-ruler_candy-ingester',
        'candy-ruler_candy-query-frontend',
        'candy-ruler_candy-distributor',
        'candy-write_candy-write',
        'controller_api',
        'gateway_api',
        'db_api',
        'eggnog-query_marshmallow-popcorn-plugin',
        'licorice-distributor_licorice-ingester',
        'licorice-gateway_licorice-distributor',
        'licorice-gateway_licorice-querier',
        'licorice-gateway_licorice-query-frontend',
        'licorice-gateway_licorice-ruler',
        'licorice-gateway_licorice-read',
        'licorice-gateway_licorice-write',
        'licorice-querier_licorice-sugar-gateway',
        'licorice-querier_licorice-ingester',
        'licorice-querier_licorice-query-frontend',
        'licorice-query-frontend_licorice-querier',
        'licorice-query-scheduler_licorice-query-frontend',
        'licorice-read_licorice-read',
        'licorice-read_licorice-write',
        'licorice-ruler_licorice-ingester',
        'licorice-ruler_licorice-sugar-gateway',
        'licorice-ruler_licorice-ruler',
        'licorice-write_licorice-write',
        'popcorn-backend_candy-read',
        'popcorn-backend_candy-write',
        'popcorn-distributor_candy-ingester',
        'popcorn-distributor_popcorn-ingester',
        'popcorn-querier_candy-ingester',
        'popcorn-querier_candy-query-frontend',
        'popcorn-querier_candy-store-gateway',
        'popcorn-querier_popcorn-ingester',
        'popcorn-querier_popcorn-query-frontend',
        'popcorn-query-frontend_popcorn-querier',
        'popcorn-ruler_candy-ingester',
        'popcorn-ruler_popcorn-query-frontend',
        'almondapi_peanutapi',
        'peanutapi_0',
        'peanutapi_worker',
        'toffee-gateway_licorice-gateway',
        'toffee-gateway_marshmallow-gateway',
        'toffee-proxy-gateway_toffee-gateway',
        'mints-agent_mints-distributor',
        'mints-distributor_mints-ingester',
        'marshmallow-gateway_marshmallow-query-frontend',
        'marshmallow-querier_marshmallow-ingester',
        'eating-scheduler_peanutapi',
        'calorie-generator_compute-splines-api',
        'worker_0',
        'worker_worker',
        'candy-backend_candy-backend',
        'candy-gateway_candy-ruler',
        'candy-packaging-exporter_candy-distributor',
        'caramel-gateway_candy-chocolate-write-proxy',
        'candy-manager_candy-distributor',
        'candy-backend_popcorn-backend',
        'candy-gateway_candy-manager',
        'candy-gateway_candy-querier',
        'candy-gateway_candy-store-gateway',
        'candy-gateway_candy-disposer',
        'candy-query-scheduler_candy-query-frontend',
        'candy-store-gateway_candy-distributor',
        'api_gateway',
        'gateway_overeat-gateway',
        'gateway_wrappers-api',
        'overeat-gateway_overeat_api',
        'overeat_api_gateway',
        'wrappers-api_caramel-gateway',
        'licorice-gateway_licorice-admin-api',
        'popcorn-backend_popcorn-backend',
        'popcorn-backend_candy-backend',
        'almondops-gateway_almond',
        'marshmallow-gateway_marshmallow-querier',
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.source,
      type: FieldType.string,
      config: {},
      values: [
        'pantry-summary',
        'compute-splines-api',
        'compute-splines-api',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'caramel-gateway',
        'proxy-gateway',
        'proxy-gateway',
        'proxy-reads',
        'proxy-writes',
        'fudge-proxy-gateway',
        'candy-admin-api',
        'candy-backend',
        'candy-backend',
        'candy-disposer',
        'candy-distributor',
        'candy-distributor',
        'candy-gateway',
        'candy-gateway',
        'candy-gateway',
        'candy-chocolate-write-proxy',
        'candy-ingester',
        'candy-querier',
        'candy-querier',
        'candy-querier',
        'candy-querier',
        'candy-query-frontend',
        'candy-query-frontend',
        'candy-query-frontend',
        'candy-query-scheduler',
        'candy-read',
        'candy-read',
        'candy-read',
        'candy-read',
        'candy-ruler',
        'candy-ruler',
        'candy-ruler',
        'candy-ruler',
        'candy-write',
        'controller',
        'gateway',
        'db',
        'eggnog-query',
        'licorice-distributor',
        'licorice-gateway',
        'licorice-gateway',
        'licorice-gateway',
        'licorice-gateway',
        'licorice-gateway',
        'licorice-gateway',
        'licorice-querier',
        'licorice-querier',
        'licorice-querier',
        'licorice-query-frontend',
        'licorice-query-scheduler',
        'licorice-read',
        'licorice-read',
        'licorice-ruler',
        'licorice-ruler',
        'licorice-ruler',
        'licorice-write',
        'popcorn-backend',
        'popcorn-backend',
        'popcorn-distributor',
        'popcorn-distributor',
        'popcorn-querier',
        'popcorn-querier',
        'popcorn-querier',
        'popcorn-querier',
        'popcorn-querier',
        'popcorn-query-frontend',
        'popcorn-ruler',
        'popcorn-ruler',
        'almondapi',
        'peanutapi',
        'peanutapi',
        'toffee-gateway',
        'toffee-gateway',
        'toffee-proxy-gateway',
        'mints-agent',
        'mints-distributor',
        'marshmallow-gateway',
        'marshmallow-querier',
        'eating-scheduler',
        'calorie-generator',
        'worker',
        'worker',
        'candy-backend',
        'candy-gateway',
        'candy-packaging-exporter',
        'caramel-gateway',
        'candy-manager',
        'candy-backend',
        'candy-gateway',
        'candy-gateway',
        'candy-gateway',
        'candy-gateway',
        'candy-query-scheduler',
        'candy-store-gateway',
        'api',
        'gateway',
        'gateway',
        'overeat-gateway',
        'overeat_api',
        'wrappers-api',
        'licorice-gateway',
        'popcorn-backend',
        'popcorn-backend',
        'almondops-gateway',
        'marshmallow-gateway',
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.target,
      type: FieldType.string,
      config: {},
      values: [
        'candycon2000',
        'candycon2000',
        'send-notification',
        'popcorn-distributor',
        'candy-distributor',
        'candy-query-frontend',
        'candy-ruler',
        'caramel-gateway',
        'candy-querier',
        'candy-chocolate-querier',
        'candy-backend',
        'candy-read',
        'candy-write',
        'popcorn-backend',
        'popcorn-query-frontend',
        'proxy-reads',
        'proxy-writes',
        'caramel-gateway',
        'caramel-gateway',
        'dentist',
        'candy-distributor',
        'candy-read',
        'candy-write',
        'candy-distributor',
        'candy-ingester',
        'candy-distributor',
        'candy-admin-api',
        'candy-distributor',
        'candy-query-frontend',
        'caramel-gateway',
        'candy-distributor',
        'candy-ingester',
        'candy-query-frontend',
        'candy-store-gateway',
        'candy-distributor',
        'candy-querier',
        'popcorn-querier',
        'candy-distributor',
        'candy-distributor',
        'candy-backend',
        'candy-read',
        'candy-write',
        'popcorn-backend',
        'candy-ruler',
        'candy-ingester',
        'candy-query-frontend',
        'candy-distributor',
        'candy-write',
        'api',
        'api',
        'api',
        'marshmallow-popcorn-plugin',
        'licorice-ingester',
        'licorice-distributor',
        'licorice-querier',
        'licorice-query-frontend',
        'licorice-ruler',
        'licorice-read',
        'licorice-write',
        'licorice-sugar-gateway',
        'licorice-ingester',
        'licorice-query-frontend',
        'licorice-querier',
        'licorice-query-frontend',
        'licorice-read',
        'licorice-write',
        'licorice-ingester',
        'licorice-sugar-gateway',
        'licorice-ruler',
        'licorice-write',
        'candy-read',
        'candy-write',
        'candy-ingester',
        'popcorn-ingester',
        'candy-ingester',
        'candy-query-frontend',
        'candy-store-gateway',
        'popcorn-ingester',
        'popcorn-query-frontend',
        'popcorn-querier',
        'candy-ingester',
        'popcorn-query-frontend',
        'peanutapi',
        '0',
        'worker',
        'licorice-gateway',
        'marshmallow-gateway',
        'toffee-gateway',
        'mints-distributor',
        'mints-ingester',
        'marshmallow-query-frontend',
        'marshmallow-ingester',
        'peanutapi',
        'compute-splines-api',
        '0',
        'worker',
        'candy-backend',
        'candy-ruler',
        'candy-distributor',
        'candy-chocolate-write-proxy',
        'candy-distributor',
        'popcorn-backend',
        'candy-manager',
        'candy-querier',
        'candy-store-gateway',
        'candy-disposer',
        'candy-query-frontend',
        'candy-distributor',
        'gateway',
        'overeat-gateway',
        'wrappers-api',
        'overeat_api',
        'gateway',
        'caramel-gateway',
        'licorice-admin-api',
        'popcorn-backend',
        'candy-backend',
        'almond',
        'marshmallow-querier',
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.mainStat,
      config: {
        unit: 'ms/r',
        displayName: 'Average response time',
      },
      type: FieldType.number,
      values: [
        0.9961658833566494,
        0.6756011854064742,
        39.928285334583585,
        9.655124202033475,
        9.876837578844937,
        81.6959176729049,
        552.378420528502,
        34.02406743798451,
        2.7907819532876537,
        null,
        1210.5999270997852,
        40.22305442474939,
        11.008270340421854,
        1163.33527417596,
        199.9200764328362,
        16.27026063145621,
        19.87661445917064,
        7.9747137654137195,
        20.135808118892268,
        0.22942839760131845,
        11.6566561294579,
        75.22426032984903,
        0.7326871862490554,
        10.591500091052806,
        0.43709820361586454,
        7.681333333333334,
        null,
        5.743161551306568,
        22.56587650604559,
        31.38290115791745,
        11.375645375977152,
        0.5974877233841939,
        0.020271039373167783,
        8.891949445529226,
        10.293999999999995,
        20.582963262267164,
        405.7442187685195,
        8.522692621843909,
        6.924993219513745,
        34.39516266769771,
        5.952498150615941,
        0.7842759237283988,
        37.01067693570632,
        6.956706372103876,
        1.2689668998692378,
        66.66971283621828,
        13.622246129080805,
        0.9078908605310487,
        0.8179566076701271,
        1.101057295936026,
        19.887110234592154,
        0.015141602590812384,
        0.16393403903361325,
        1.753552009081408,
        1531278.5796188207,
        46.231810748849455,
        24.54774899949519,
        345061.91461907607,
        3.1331808761849147,
        2.764448605690192,
        2759.490189311903,
        0.019420568056168524,
        30.83859939288313,
        0.026999999999999993,
        2.405242467897073,
        14607.641090479348,
        11.449968675469439,
        0.33363793667426944,
        0.15786000187388566,
        0.585213874210476,
        41.39677023252635,
        0.12979907013653832,
        2.5827714948826848,
        6.868234219946023,
        21.107071160645475,
        0.053417204821597085,
        61.770970442855806,
        42.998532007278904,
        0.041043219084661645,
        217.39742114991702,
        0.21606213948511854,
        13.174790215465455,
        459.42768834463334,
        0.30539630255527017,
        447.5818111167013,
        2.18723509171844,
        3.22412985358373,
        4.200223281332062,
        16.03316977154454,
        5.830408312271875,
        1460.8431543525896,
        6.379590630002257,
        37.46723333208206,
        16.20695699632165,
        0.4593149548828589,
        2693.186775574188,
        0.034024049217002236,
        196.843,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
    {
      name: NodeGraphDataFrameFieldNames.secondaryStat,
      config: {
        unit: 'r/sec',
        displayName: 'Requests per second',
      },
      type: FieldType.number,
      values: [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        null,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0.03,
        0,
        null,
        0,
        0,
        0,
        0,
        0.07,
        0.01,
        0,
        0,
        0.01,
        0,
        0,
        0,
        0,
        0,
        0.02,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0.05,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
    },
  ],
  meta: { preferredVisualisationType: 'nodeGraph' as const },
  name: 'edges',
};
