import { DataFrameDTO, FieldType } from '@grafana/data';

export const flameGraphData: DataFrameDTO = {
  name: 'response',
  // @ts-ignore
  meta: { preferredVisualisationType: 'flamegraph' },
  fields: [
    {
      name: 'level',
      values: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 11, 12, 13, 14, 15, 16, 10, 11, 12, 13, 9, 10, 9, 9, 7, 7, 6, 5, 6, 7,
        8, 8, 9, 10, 11, 12, 13, 8, 9, 10, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 4, 5, 6, 7, 8, 9, 9, 10, 11, 12, 11, 12,
        13, 8, 8, 7, 8, 8, 7, 6, 7, 8, 9, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 9, 8, 8, 7, 8, 6, 7, 8, 8, 7, 6, 7, 8,
        8, 8, 9, 10, 11, 12, 4, 5, 6, 7, 8, 9, 9, 10, 11, 9, 10, 7, 4, 5, 6, 7, 8, 8, 7, 8, 9, 10, 11, 12, 13, 10, 10,
        11, 9, 10, 10, 11, 12, 13, 14, 15, 16, 17, 8, 9, 5, 6, 7, 6, 7, 6, 5, 6, 6, 6, 6, 5, 6, 7, 6, 5, 6, 7, 8, 4, 5,
        6, 5, 6, 5, 4, 5, 5, 3, 4, 5, 6, 7, 7, 8, 7, 8, 9, 8, 9, 8, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
        14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 24, 24, 25, 26, 24, 25, 22, 23, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        32, 21, 22, 23, 24, 25, 26, 27, 26, 24, 25, 26, 25, 24, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 25, 26,
        27, 25, 26, 24, 23, 24, 24, 25, 23, 23, 22, 23, 24, 24, 23, 24, 25, 26, 24, 25, 23, 24, 24, 25, 23, 23, 24, 24,
        23, 23, 23, 23, 23, 24, 25, 22, 23, 24, 25, 22, 23, 24, 25, 26, 27, 28, 25, 26, 27, 28, 29, 24, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 21, 22, 23, 23, 24, 24, 24, 22, 23, 24, 25, 24, 23, 24, 24, 23, 22, 23, 24, 25,
        25, 26, 27, 24, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 24, 21, 22, 23, 24, 25, 26, 27, 28, 21, 22, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 24, 25, 26, 27, 28, 23, 24, 25, 26, 27, 28, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 31, 30, 28, 29, 30, 31, 32, 33, 34,
        35, 36, 37, 29, 30, 31, 26, 27, 28, 28, 28, 29, 27, 26, 27, 26, 25, 26, 27, 28, 29, 30, 31, 32, 32, 33, 34, 30,
        31, 32, 33, 34, 33, 34, 35, 36, 37, 38, 39, 29, 30, 31, 32, 32, 27, 28, 28, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        36, 37, 38, 39, 40, 27, 28, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 28, 27, 28, 28, 29, 27, 27, 28, 27, 27, 26,
        27, 28, 28, 29, 29, 29, 26, 27, 27, 28, 29, 30, 29, 30, 30, 29, 30, 31, 28, 29, 30, 28, 28, 28, 28, 29, 30, 29,
        30, 27, 28, 28, 26, 27, 26, 27, 27, 28, 29, 26, 27, 28, 29, 28, 27, 28, 29, 30, 30, 28, 29, 30, 31, 32, 32, 27,
        28, 26, 25, 25, 26, 27, 28, 29, 30, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47,
        48, 31, 30, 31, 32, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 29, 26, 27, 28,
        29, 30, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 31, 30, 29, 29, 29, 25, 26, 27, 28, 26,
        26, 27, 28, 27, 28, 28, 26, 27, 28, 28, 27, 28, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 25, 25, 26, 27, 28, 25,
        26, 24, 25, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 33, 34, 34, 32, 33, 34, 34, 32, 28, 29, 30, 31, 27,
        28, 29, 26, 27, 28, 29, 30, 31, 32, 33, 34, 26, 27, 28, 29, 24, 25, 26, 27, 28, 29, 30, 27, 28, 26, 27, 25, 26,
        27, 28, 29, 26, 25, 26, 27, 26, 27, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 38, 39, 30, 31, 32, 26, 27,
        28, 27, 28, 29, 25, 26, 27, 28, 29, 30, 31, 32, 28, 29, 28, 29, 28, 29, 27, 28, 29, 30, 31, 29, 30, 31, 32, 33,
        34, 35, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 28, 29, 30, 31, 28, 29, 30, 31, 26, 25, 26, 27, 28, 29,
        27, 28, 29, 30, 25, 26, 15, 14, 15, 16, 17, 13, 14, 15, 16, 15, 16, 17, 18, 19, 20, 12, 13, 14, 15, 16, 17, 10,
        11, 12, 13, 14, 12, 13, 10, 11, 12, 13, 14, 15, 2, 3, 4, 5, 6, 7, 8, 4, 5, 6, 7, 8, 9, 2, 3, 1, 2, 3, 4, 5, 6,
        6, 6, 6, 7, 8, 8, 9, 6, 7, 6, 6, 6, 6, 5, 5, 6, 5, 5, 5, 5, 5, 6, 7, 4, 2, 3, 4, 5, 6, 4, 5, 6, 7, 8, 9, 1, 2,
        3, 4, 5, 6, 7, 8, 2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6, 1, 2, 3, 3, 4, 3, 2, 1, 2, 3, 4, 5, 6, 5, 6, 7, 8, 5,
        5, 6, 7, 4, 5, 6, 4, 2, 3, 4, 3, 4, 5, 6, 5, 5, 6, 4, 1, 2, 3, 4, 5, 6, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        23, 16, 17, 18, 19, 16, 17, 18, 19, 20, 21, 22, 23, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        23, 8, 9, 10, 11, 12, 13, 14, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4,
      ],
    },
    {
      name: 'value',
      values: [
        16460000000, 4100000000, 4100000000, 3670000000, 1130000000, 1060000000, 1020000000, 150000000, 150000000,
        50000000, 30000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 80000000, 40000000, 10000000, 10000000, 530000000, 80000000, 40000000, 70000000,
        50000000, 50000000, 30000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 10000000,
        390000000, 390000000, 180000000, 70000000, 50000000, 10000000, 20000000, 20000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 20000000, 10000000, 10000000, 10000000, 110000000, 80000000, 60000000,
        10000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        20000000, 10000000, 10000000, 30000000, 20000000, 40000000, 30000000, 20000000, 10000000, 10000000, 40000000,
        40000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 210000000, 210000000, 210000000,
        130000000, 130000000, 10000000, 50000000, 40000000, 40000000, 70000000, 10000000, 40000000, 1840000000,
        240000000, 240000000, 50000000, 10000000, 10000000, 190000000, 130000000, 50000000, 20000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 80000000, 20000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 60000000, 10000000, 1330000000, 1290000000, 320000000, 10000000,
        10000000, 10000000, 130000000, 10000000, 10000000, 60000000, 30000000, 100000000, 40000000, 10000000, 10000000,
        40000000, 30000000, 10000000, 10000000, 70000000, 10000000, 10000000, 50000000, 40000000, 10000000, 30000000,
        20000000, 10000000, 430000000, 430000000, 430000000, 430000000, 10000000, 40000000, 20000000, 370000000,
        20000000, 10000000, 180000000, 10000000, 30000000, 10000000, 10000000, 10000000, 5630000000, 5580000000,
        5580000000, 5580000000, 5580000000, 5580000000, 5580000000, 5580000000, 5580000000, 5540000000, 5540000000,
        5530000000, 5510000000, 5500000000, 5490000000, 5490000000, 1610000000, 1610000000, 1540000000, 1540000000,
        190000000, 170000000, 170000000, 10000000, 20000000, 120000000, 10000000, 10000000, 20000000, 20000000,
        20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 850000000, 350000000, 300000000, 120000000, 110000000, 80000000, 20000000, 10000000, 80000000,
        40000000, 20000000, 10000000, 10000000, 50000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 20000000,
        20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 370000000, 40000000, 20000000, 10000000, 100000000,
        10000000, 10000000, 10000000, 30000000, 30000000, 80000000, 20000000, 10000000, 10000000, 10000000, 40000000,
        10000000, 10000000, 10000000, 20000000, 10000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 110000000, 110000000, 60000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 480000000, 150000000, 10000000, 130000000, 10000000, 10000000,
        50000000, 140000000, 80000000, 40000000, 10000000, 10000000, 20000000, 10000000, 10000000, 20000000, 120000000,
        60000000, 40000000, 10000000, 10000000, 10000000, 10000000, 20000000, 30000000, 30000000, 20000000, 20000000,
        20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 60000000, 60000000, 60000000, 60000000,
        30000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 20000000, 20000000, 20000000, 20000000, 20000000, 30000000, 30000000, 30000000, 30000000,
        30000000, 30000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 3880000000, 3880000000, 3880000000,
        3880000000, 3870000000, 3230000000, 3230000000, 3210000000, 320000000, 70000000, 70000000, 40000000, 40000000,
        20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 20000000,
        30000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 20000000,
        20000000, 20000000, 70000000, 60000000, 20000000, 30000000, 10000000, 10000000, 10000000, 100000000, 20000000,
        50000000, 1850000000, 410000000, 110000000, 110000000, 90000000, 60000000, 60000000, 40000000, 20000000,
        20000000, 20000000, 30000000, 30000000, 30000000, 10000000, 10000000, 20000000, 20000000, 20000000, 20000000,
        20000000, 20000000, 10000000, 20000000, 20000000, 20000000, 10000000, 10000000, 30000000, 20000000, 10000000,
        40000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 40000000, 10000000, 80000000, 30000000, 30000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 20000000, 60000000, 30000000, 30000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 160000000, 120000000, 20000000, 100000000, 50000000, 10000000, 10000000,
        640000000, 10000000, 550000000, 410000000, 80000000, 60000000, 260000000, 20000000, 170000000, 60000000,
        30000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000, 20000000, 50000000, 40000000, 10000000,
        10000000, 10000000, 70000000, 30000000, 40000000, 290000000, 90000000, 30000000, 10000000, 10000000, 10000000,
        10000000, 210000000, 20000000, 10000000, 10000000, 10000000, 120000000, 90000000, 90000000, 60000000, 10000000,
        30000000, 30000000, 30000000, 30000000, 10000000, 20000000, 50000000, 10000000, 10000000, 10000000, 280000000,
        150000000, 150000000, 150000000, 60000000, 30000000, 80000000, 50000000, 20000000, 20000000, 20000000, 20000000,
        20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 130000000, 130000000, 130000000, 70000000, 30000000, 30000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 40000000, 10000000, 590000000, 20000000,
        20000000, 10000000, 40000000, 150000000, 30000000, 30000000, 120000000, 10000000, 80000000, 370000000,
        260000000, 40000000, 150000000, 110000000, 90000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 20000000, 20000000, 20000000, 70000000, 60000000,
        20000000, 20000000, 640000000, 640000000, 250000000, 250000000, 230000000, 180000000, 170000000, 170000000,
        170000000, 170000000, 50000000, 10000000, 40000000, 10000000, 10000000, 60000000, 60000000, 10000000, 30000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 50000000, 50000000, 20000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 390000000,
        60000000, 40000000, 30000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 30000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 90000000, 10000000, 10000000, 60000000, 10000000, 40000000,
        40000000, 40000000, 30000000, 30000000, 30000000, 30000000, 30000000, 30000000, 30000000, 30000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        180000000, 170000000, 50000000, 20000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 30000000, 30000000, 20000000, 20000000, 20000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 90000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 70000000, 70000000,
        70000000, 70000000, 10000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 20000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 20000000,
        20000000, 20000000, 20000000, 10000000, 40000000, 40000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        30000000, 30000000, 30000000, 30000000, 30000000, 30000000, 10000000, 10000000, 6190000000, 6170000000,
        6170000000, 6070000000, 4460000000, 10000000, 50000000, 440000000, 540000000, 20000000, 10000000, 10000000,
        10000000, 980000000, 10000000, 50000000, 480000000, 540000000, 50000000, 210000000, 30000000, 30000000,
        60000000, 180000000, 10000000, 210000000, 20000000, 20000000, 20000000, 100000000, 20000000, 20000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 40000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000,
        20000000, 20000000, 10000000, 10000000, 10000000, 10000000, 10000000, 170000000, 160000000, 70000000, 30000000,
        10000000, 40000000, 10000000, 170000000, 90000000, 90000000, 70000000, 20000000, 10000000, 30000000, 30000000,
        30000000, 30000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 80000000,
        10000000, 10000000, 70000000, 60000000, 10000000, 10000000, 20000000, 20000000, 20000000, 10000000, 80000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 20000000, 20000000, 20000000, 20000000, 20000000, 20000000,
        20000000, 20000000, 20000000, 20000000, 20000000, 20000000, 50000000, 50000000, 50000000, 50000000, 50000000,
        50000000, 50000000, 10000000, 10000000, 10000000, 60000000, 60000000, 60000000, 60000000, 60000000, 60000000,
        60000000, 40000000, 40000000, 30000000, 30000000, 30000000, 30000000, 30000000, 30000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,
        10000000, 10000000,
      ],
      config: {
        unit: 'short',
      },
    },
    {
      name: 'self',
      values: [
        16460, 4100, 4100, 3670, 1130, 1060, 1020, 150, 150, 50, 30, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 80,
        40, 10, 10, 530, 80, 40, 70, 50, 50, 30, 10, 10, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 20, 20, 20, 20,
        10, 390, 390, 180, 70, 50, 10, 20, 20, 10, 10, 10, 10, 10, 10, 10, 20, 10, 10, 10, 110, 80, 60, 10, 20, 20, 20,
        10, 10, 10, 10, 10, 10, 10, 20, 10, 10, 30, 20, 40, 30, 20, 10, 10, 40, 40, 10, 10, 10, 10, 10, 10, 10, 210,
        210, 210, 130, 130, 10, 50, 40, 40, 70, 10, 40, 1840, 240, 240, 50, 10, 10, 190, 130, 50, 20, 10, 10, 10, 10,
        10, 10, 80, 20, 10, 10, 10, 10, 10, 10, 10, 10, 60, 10, 1330, 1290, 320, 10, 10, 10, 130, 10, 10, 60, 30, 100,
        40, 10, 10, 40, 30, 10, 10, 70, 10, 10, 50, 40, 10, 30, 20, 10, 430, 430, 430, 430, 10, 40, 20, 370, 20, 10,
        180, 10, 30, 10, 10, 10, 5630, 5580, 5580, 5580, 5580, 5580, 5580, 5580, 5580, 5540, 5540, 5530, 5510, 5500,
        5490, 5490, 1610, 1610, 1540, 1540, 190, 170, 170, 10, 20, 120, 10, 10, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 850, 350, 300, 120, 110, 80, 20, 10, 80, 40, 20, 10, 10, 50, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 20, 20, 20, 20, 10, 10, 10, 10, 10, 370, 40, 20, 10, 100, 10, 10, 10, 30, 30, 80, 20, 10,
        10, 10, 40, 10, 10, 10, 20, 10, 20, 10, 10, 10, 10, 10, 10, 10, 110, 110, 60, 20, 20, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 480, 150, 10, 130, 10, 10, 50, 140, 80, 40, 10, 10, 20,
        10, 10, 20, 120, 60, 40, 10, 10, 10, 10, 20, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 60, 60, 60, 60, 30, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 30,
        30, 30, 30, 30, 30, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 3880, 3880, 3880, 3880,
        3870, 3230, 3230, 3210, 320, 70, 70, 40, 40, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 20, 30, 10, 10, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 70, 60, 20, 30, 10, 10, 10, 100, 20, 50,
        1850, 410, 110, 110, 90, 60, 60, 40, 20, 20, 20, 30, 30, 30, 10, 10, 20, 20, 20, 20, 20, 20, 10, 20, 20, 20, 10,
        10, 30, 20, 10, 40, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 40, 10, 80, 30, 30, 10, 10, 10, 10, 10,
        10, 10, 20, 60, 30, 30, 10, 10, 10, 10, 10, 10, 160, 120, 20, 100, 50, 10, 10, 640, 10, 550, 410, 80, 60, 260,
        20, 170, 60, 30, 20, 20, 20, 10, 10, 10, 20, 50, 40, 10, 10, 10, 70, 30, 40, 290, 90, 30, 10, 10, 10, 10, 210,
        20, 10, 10, 10, 120, 90, 90, 60, 10, 30, 30, 30, 30, 10, 20, 50, 10, 10, 10, 280, 150, 150, 150, 60, 30, 80, 50,
        20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 130, 130, 130, 70, 30, 30, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 40, 10, 590, 20, 20, 10, 40, 150, 30, 30, 120, 10, 80, 370, 260, 40,
        150, 110, 90, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 70, 60, 20, 20, 640, 640, 250, 250,
        230, 180, 170, 170, 170, 170, 50, 10, 40, 10, 10, 60, 60, 10, 30, 10, 10, 10, 10, 10, 50, 50, 20, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 390, 60, 40, 30, 20, 20, 10, 10, 10, 10, 10, 30, 10, 10, 10, 10, 10, 90,
        10, 10, 60, 10, 40, 40, 40, 30, 30, 30, 30, 30, 30, 30, 30, 10, 10, 10, 10, 10, 10, 20, 10, 10, 10, 10, 10, 180,
        170, 50, 20, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 30, 30, 20, 20, 20, 10, 10, 10, 10, 10, 10, 10, 90, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 70, 70, 70, 70, 10, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 20, 20, 10, 10, 10, 10,
        10, 20, 20, 20, 20, 20, 10, 40, 40, 10, 10, 10, 10, 10, 30, 30, 30, 30, 30, 30, 10, 10, 6190, 6170, 6170, 6070,
        4460, 10, 50, 440, 540, 20, 10, 10, 10, 980, 10, 50, 480, 540, 50, 210, 30, 30, 60, 180, 10, 210, 20, 20, 20,
        100, 20, 20, 10, 10, 10, 10, 10, 10, 10, 10, 10, 40, 10, 10, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 20, 20, 20,
        10, 10, 10, 10, 10, 170, 160, 70, 30, 10, 40, 10, 170, 90, 90, 70, 20, 10, 30, 30, 30, 30, 10, 10, 10, 10, 10,
        10, 10, 10, 80, 10, 10, 70, 60, 10, 10, 20, 20, 20, 10, 80, 10, 10, 10, 10, 10, 20, 20, 20, 20, 20, 20, 20, 20,
        20, 20, 20, 20, 50, 50, 50, 50, 50, 50, 50, 10, 10, 10, 60, 60, 60, 60, 60, 60, 60, 40, 40, 30, 30, 30, 30, 30,
        30, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
        10, 10, 10, 10,
      ],
      config: {
        unit: 'short',
      },
    },
    {
      name: 'label',
      type: FieldType.enum,
      values: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 15, 15, 16, 17, 14, 15, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 28, 30, 31, 32, 33, 34, 35, 41, 42, 43, 44, 40, 28,
        29, 45, 46, 47, 48, 49, 50, 51, 52, 53, 27, 53, 28, 54, 55, 27, 28, 56, 45, 46, 49, 50, 51, 31, 57, 58, 59, 60,
        36, 53, 52, 54, 28, 54, 28, 36, 29, 12, 27, 28, 36, 29, 45, 46, 47, 48, 61, 62, 63, 6, 7, 8, 21, 9, 10, 11, 19,
        20, 23, 64, 14, 15, 18, 65, 66, 15, 15, 15, 15, 15, 16, 65, 18, 16, 66, 18, 65, 66, 67, 68, 69, 31, 70, 71, 72,
        18, 65, 73, 74, 75, 54, 12, 12, 76, 77, 78, 79, 80, 81, 82, 83, 84, 74, 85, 74, 86, 87, 88, 89, 90, 91, 92, 90,
        93, 91, 94, 95, 96, 97, 12, 98, 12, 99, 100, 12, 101, 102, 103, 104, 99, 103, 105, 106, 107, 108, 109, 107, 110,
        107, 111, 107, 112, 107, 113, 114, 115, 116, 117, 118, 119, 120, 95, 121, 122, 123, 124, 125, 126, 12, 127, 125,
        54, 12, 28, 128, 51, 31, 57, 58, 129, 130, 131, 132, 133, 134, 135, 136, 137, 82, 83, 138, 139, 79, 12, 77, 82,
        140, 141, 142, 143, 28, 144, 145, 146, 31, 147, 148, 149, 150, 54, 151, 152, 153, 154, 27, 155, 28, 36, 156,
        157, 135, 158, 159, 160, 140, 150, 54, 53, 152, 153, 161, 160, 162, 159, 163, 164, 78, 79, 165, 166, 154, 138,
        167, 137, 82, 168, 169, 170, 171, 172, 173, 140, 152, 153, 174, 175, 141, 142, 143, 28, 36, 82, 176, 28, 45, 46,
        49, 50, 51, 31, 57, 58, 59, 60, 43, 27, 53, 28, 177, 56, 36, 55, 54, 28, 36, 53, 27, 52, 28, 178, 44, 40, 28,
        29, 45, 46, 49, 155, 54, 28, 144, 145, 146, 31, 147, 148, 179, 180, 181, 29, 182, 183, 184, 185, 186, 187, 180,
        188, 189, 190, 191, 192, 193, 194, 195, 62, 4, 26, 27, 28, 30, 31, 32, 33, 34, 35, 196, 197, 198, 199, 200, 29,
        201, 197, 198, 199, 200, 29, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
        218, 107, 219, 220, 221, 222, 223, 224, 225, 226, 4, 5, 227, 228, 19, 20, 229, 230, 231, 232, 233, 234, 235,
        236, 237, 235, 236, 238, 239, 240, 241, 242, 217, 243, 244, 26, 245, 40, 28, 30, 31, 32, 33, 34, 35, 27, 28, 29,
        246, 247, 158, 248, 249, 12, 250, 251, 12, 252, 253, 254, 226, 4, 26, 27, 28, 29, 36, 37, 38, 245, 40, 28, 255,
        29, 30, 31, 32, 33, 34, 35, 41, 5, 227, 228, 19, 9, 54, 28, 155, 256, 141, 142, 143, 28, 144, 145, 146, 31, 147,
        148, 179, 180, 181, 257, 159, 140, 152, 153, 258, 175, 69, 31, 70, 71, 196, 83, 259, 248, 249, 12, 260, 249, 12,
        261, 248, 262, 27, 53, 28, 36, 29, 56, 263, 260, 186, 187, 264, 265, 180, 188, 181, 266, 267, 268, 269, 267,
        268, 149, 261, 270, 271, 180, 181, 264, 265, 27, 53, 28, 82, 83, 272, 149, 271, 264, 265, 273, 54, 28, 36, 12,
        256, 274, 275, 159, 276, 141, 142, 143, 28, 29, 36, 158, 159, 277, 278, 279, 4, 5, 227, 244, 280, 228, 19, 20,
        229, 230, 231, 232, 233, 234, 235, 236, 237, 235, 236, 238, 239, 240, 241, 242, 217, 281, 9, 10, 11, 282, 20,
        229, 230, 231, 232, 233, 234, 235, 236, 237, 235, 236, 238, 239, 240, 241, 242, 217, 283, 62, 63, 227, 228, 9,
        19, 281, 229, 230, 231, 232, 233, 234, 235, 236, 237, 284, 285, 286, 287, 288, 20, 289, 283, 244, 290, 291, 27,
        28, 36, 149, 271, 264, 265, 180, 188, 181, 187, 180, 188, 181, 264, 265, 292, 293, 294, 54, 28, 30, 31, 32, 33,
        34, 260, 295, 269, 267, 268, 296, 297, 298, 260, 299, 300, 301, 302, 303, 304, 31, 305, 306, 148, 179, 264, 180,
        181, 188, 307, 180, 188, 181, 149, 308, 309, 269, 267, 310, 308, 309, 311, 31, 312, 313, 314, 315, 316, 317,
        318, 31, 319, 306, 148, 320, 321, 284, 285, 286, 287, 288, 322, 12, 323, 53, 256, 141, 142, 143, 53, 159, 253,
        273, 324, 254, 259, 226, 4, 26, 245, 40, 28, 30, 31, 32, 33, 34, 325, 35, 326, 27, 28, 29, 263, 27, 53, 186,
        187, 180, 292, 293, 327, 328, 329, 330, 331, 332, 333, 334, 323, 53, 335, 54, 336, 329, 330, 331, 332, 337, 338,
        339, 31, 340, 341, 342, 294, 54, 28, 45, 46, 49, 50, 51, 31, 57, 58, 343, 344, 345, 346, 217, 347, 215, 216,
        217, 27, 14, 15, 18, 348, 349, 15, 16, 348, 257, 225, 251, 27, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359,
        360, 361, 362, 363, 364, 182, 183, 184, 185, 146, 365, 366, 367, 352, 353, 27, 53, 368, 355, 358, 359, 360, 361,
        369, 236, 237, 370, 371, 372, 373, 238, 239, 240, 241, 242, 217, 374, 375, 376, 31, 377, 378, 35, 379, 380, 41,
        326, 381, 382, 383, 384, 72, 196, 385, 196, 386, 387, 56, 388, 389, 390, 391, 392, 325, 393, 394, 382, 395, 396,
        397, 398, 399, 400, 31, 401, 402, 403, 404, 405, 406, 407, 408, 409, 31, 410, 411, 412, 413, 414, 415, 213, 214,
        215, 216, 217, 416, 417, 27, 28, 29, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431, 432,
        433, 318, 434, 435, 436, 437, 438, 439, 440, 441, 442, 443, 439, 444, 427, 445, 446, 435, 428, 435, 446, 447,
        448, 449, 450, 352, 353, 451, 452, 408, 409, 31, 410, 411, 314, 315, 316, 317, 318, 236, 453, 239, 240, 241,
        242, 217, 454, 455, 456, 457, 458, 459, 460, 461, 462, 463, 464, 203, 465, 205, 466, 467, 468, 469, 470, 40, 28,
        45, 46, 49, 50, 471, 472, 473, 474, 148, 475, 476, 473, 474, 148, 307, 180, 181, 191, 477, 193, 478, 479, 480,
        481, 482, 483, 67, 68, 69, 31, 70, 71, 72, 484, 485, 486, 487, 27, 28, 36, 488, 489, 490, 40, 28, 30, 31, 32,
        33, 34, 35, 41, 491, 213, 214, 492,
      ],
      config: {
        type: {
          enum: {
            text: [
              'total',
              'test/pkg/agent.(*Target).start.func1',
              'test/pkg/agent.(*Target).scrape',
              'test/pkg/distributor.(*Distributor).Push',
              'compress/gzip.(*Writer).Write',
              'compress/flate.(*compressor).write',
              'compress/flate.(*compressor).deflate',
              'compress/flate.(*compressor).writeBlock',
              'compress/flate.(*huffmanBitWriter).writeBlock',
              'compress/flate.(*huffmanBitWriter).indexTokens',
              'compress/flate.(*huffmanEncoder).generate',
              'compress/flate.(*huffmanEncoder).bitCounts',
              'runtime.memmove',
              'compress/flate.(*huffmanEncoder).assignEncodingAndSize',
              'sort.Sort',
              'sort.quickSort',
              'sort.insertionSort',
              'compress/flate.(*byFreq).sort',
              'sort.doPivot',
              'compress/flate.(*huffmanBitWriter).writeTokens',
              'compress/flate.(*huffmanBitWriter).writeCode',
              'compress/flate.offsetCode',
              'compress/flate.(*huffmanBitWriter).dynamicSize',
              'compress/flate.(*compressor).findMatch',
              'compress/flate.matchLen',
              'compress/flate.hash4',
              'compress/flate.NewWriter',
              'runtime.newobject',
              'runtime.mallocgc',
              'runtime.memclrNoHeapPointers',
              'runtime.gcAssistAlloc',
              'runtime.systemstack',
              'runtime.gcAssistAlloc.func1',
              'runtime.gcAssistAlloc1',
              'runtime.gcDrainN',
              'runtime.scanobject',
              'runtime.heapBitsSetType',
              'runtime.heapBitsSetTypeGCProg',
              'runtime.runGCProg',
              'compress/flate.(*compressor).initDeflate',
              'runtime.makeslice',
              'runtime.pageIndexOf',
              'test/pkg/pprof.OpenRaw',
              'test/pkg/gen/google/v1.(*Profile).UnmarshalVT',
              'test/pkg/gen/google/v1.(*Sample).UnmarshalVT',
              'runtime.(*mcache).nextFree',
              'runtime.(*mcache).refill',
              'runtime.(*mcentral).uncacheSpan',
              'runtime.(*spanSet).push',
              'runtime.(*mcentral).cacheSpan',
              'runtime.(*mcentral).grow',
              'runtime.(*mheap).alloc',
              'runtime.getMCache',
              'runtime.nextFreeFast',
              'runtime.growslice',
              'test/pkg/gen/google/v1.(*Location).UnmarshalVT',
              'runtime.heapBitsForAddr',
              'runtime.(*mheap).alloc.func1',
              'runtime.(*mheap).allocSpan',
              'runtime.(*mheap).allocMSpanLocked',
              'runtime.(*fixalloc).alloc',
              'runtime.(*headTailIndex).incTail',
              'compress/gzip.(*Writer).Close',
              'compress/flate.(*compressor).close',
              'test/pkg/pprof.(*Profile).Normalize',
              'test/pkg/pprof.(*sortedSample).Less',
              'test/pkg/pprof.(*sortedSample).Swap',
              'runtime.gcWriteBarrierR8',
              'runtime.gcWriteBarrier',
              'runtime.wbBufFlush',
              'runtime.wbBufFlush.func1',
              'runtime.wbBufFlush1',
              'runtime.findObject',
              'test/pkg/pprof.(*Profile).clearSampleReferences',
              'test/pkg/slices.RemoveInPlace[...]',
              'test/pkg/pprof.(*Profile).clearSampleReferences.func1',
              'test/pkg/pprof.StacktracesHasher.Hashes',
              'github.com/cespare/xxhash/v2.round',
              'github.com/cespare/xxhash/v2.(*Digest).Sum64',
              'github.com/cespare/xxhash/v2.(*Digest).Write',
              'github.com/cespare/xxhash/v2.u64',
              'github.com/samber/lo.Uniq[...]',
              'runtime.mapaccess2_fast64',
              'runtime.memhash64',
              'runtime.bucketMask',
              'test/pkg/pprof.(*Profile).Normalize.func1',
              'test/pkg/pprof.(*Profile).Normalize.func1.1',
              'test/pkg/gen/google/v1.(*Profile).MarshalToVT',
              'test/pkg/gen/google/v1.(*Profile).MarshalToSizedBufferVT',
              'test/pkg/gen/google/v1.(*Sample).MarshalToSizedBufferVT',
              'test/pkg/gen/google/v1.(*Profile).SizeVT',
              'test/pkg/gen/google/v1.(*Sample).SizeVT',
              'test/pkg/gen/google/v1.encodeVarint',
              'test/pkg/gen/google/v1.(*Location).SizeVT',
              'io/ioutil.ReadAll',
              'io.ReadAll',
              'compress/gzip.(*Reader).Read',
              'compress/flate.(*decompressor).Read',
              'compress/flate.(*dictDecoder).tryWriteCopy',
              'compress/flate.(*decompressor).huffmanBlock',
              'compress/flate.(*dictDecoder).writeCopy',
              'compress/flate.(*decompressor).huffSym',
              'bytes.(*Reader).ReadByte',
              'compress/flate.(*decompressor).moreBits',
              'compress/flate.(*decompressor).nextBlock',
              'net/http.(*conn).serve',
              'net/http.serverHandler.ServeHTTP',
              'net/http.HandlerFunc.ServeHTTP',
              'test/pkg/util.glob..func1.1',
              'golang.org/x/net/http2/h2c.h2cHandler.ServeHTTP',
              'test/pkg/create.(*create).initServer.func2.1',
              'github.com/opentracing-contrib/go-stdlib/nethttp.MiddlewareFunc.func5',
              'github.com/weaveworks/common/middleware.Log.Wrap.func1',
              'github.com/weaveworks/common/middleware.Instrument.Wrap.func1',
              'github.com/felixge/httpsnoop.(*Metrics).CaptureMetrics',
              'github.com/weaveworks/common/middleware.Instrument.Wrap.func1.2',
              'github.com/gorilla/mux.(*Router).ServeHTTP',
              'github.com/bufbuild/connect-go.(*Handler).ServeHTTP',
              'github.com/bufbuild/connect-go.NewUnaryHandler[...].func1',
              'github.com/bufbuild/connect-go.NewUnaryHandler[...].func1.1',
              'test/pkg/ingester.(*Ingester).Push',
              'github.com/klauspost/compress/gzip.(*Reader).Read',
              'github.com/klauspost/compress/flate.(*decompressor).Read',
              'github.com/klauspost/compress/flate.(*dictDecoder).tryWriteCopy',
              'github.com/klauspost/compress/flate.(*dictDecoder).writeByte',
              'github.com/klauspost/compress/flate.(*decompressor).huffmanBytesReader',
              'github.com/klauspost/compress/flate.(*dictDecoder).writeCopy',
              'github.com/klauspost/compress/flate.(*decompressor).nextBlock',
              'runtime.(*mcache).allocLarge',
              'runtime.(*pageAlloc).alloc',
              'runtime.(*pageAlloc).allocRange',
              'runtime.(*pageAlloc).update',
              'runtime.(*pallocBits).summarize',
              'test/pkg/create.(*Head).Ingest',
              'test/pkg/create.(*Head).convertSamples',
              'test/pkg/create.(*deduplicatingSlice[...]).ingest',
              'test/pkg/create.(*stacktracesHelper).rewrite',
              'test/pkg/create.idConversionTable.rewriteUint64',
              'runtime.add',
              'test/pkg/create.(*stacktracesHelper).key',
              'runtime.mapassign_fast64',
              'runtime.hashGrow',
              'runtime.makeBucketArray',
              'runtime.newarray',
              'runtime.profilealloc',
              'runtime.mProf_Malloc',
              'runtime.callers',
              'runtime.callers.func1',
              'runtime.gentraceback',
              'runtime.findfunc',
              'runtime.(*hmap).newoverflow',
              'runtime.releasem',
              'runtime.growWork_fast64',
              'runtime.evacuate_fast64',
              'encoding/binary.littleEndian.PutUint64',
              'runtime.acquirem',
              'test/pkg/create.(*labelCache).rewriteLabels',
              'runtime.mapaccess1_fast64',
              'runtime.mapaccess2_faststr',
              'aeshashbody',
              'memeqbody',
              'runtime.mapaccess2',
              'runtime.memhash_varlen',
              'runtime.evacuated',
              'test/pkg/create.(*locationsHelper).key',
              'runtime.bucketShift',
              'test/pkg/create.(*stringsHelper).addToRewriter',
              'test/pkg/create.(*locationsHelper).rewrite',
              'github.com/prometheus/client_golang/prometheus.(*CounterVec).WithLabelValues',
              'github.com/prometheus/client_golang/prometheus.(*MetricVec).GetMetricWithLabelValues',
              'github.com/prometheus/client_golang/prometheus.(*MetricVec).hashLabelValues',
              'github.com/prometheus/client_golang/prometheus.hashAdd',
              'test/pkg/create.(*deltaProfiles).computeDelta',
              'test/pkg/create.deltaSamples',
              'runtime.typedmemmove',
              'runtime.bulkBarrierPreWrite',
              'test/pkg/create.copySlice[...]',
              'runtime.add1',
              'runtime.roundupsize',
              'runtime.pcdatavalue',
              'runtime.pcvalue',
              'runtime.step',
              'github.com/go-kit/log.(*context).Log',
              'github.com/go-kit/log.bindValues',
              'github.com/go-kit/log.Caller.func1',
              'runtime.Caller',
              'runtime.(*Frames).Next',
              'runtime.funcline1',
              'runtime.readvarint',
              'test/pkg/gen/google/v1.(*Profile).ReturnToVTPool',
              'test/pkg/gen/google/v1.(*Profile).ResetVT',
              'github.com/bufbuild/connect-go.(*errorTranslatingSender).Send',
              'github.com/bufbuild/connect-go.(*connectUnaryHandlerSender).Send',
              'github.com/bufbuild/connect-go.(*connectUnaryMarshaler).Marshal',
              'github.com/bufbuild/connect-go.(*compressionPool).Compress',
              'github.com/bufbuild/connect-go.(*compressionPool).putCompressor',
              'runtime.spanOf',
              'compress/gzip.(*Writer).Reset',
              'compress/gzip.(*Writer).init',
              'compress/flate.(*Writer).Reset',
              'compress/flate.(*compressor).reset',
              'github.com/bufbuild/connect-go.(*compressionPool).getCompressor',
              'github.com/bufbuild/connect-go.receiveUnaryRequest[...]',
              'github.com/bufbuild/connect-go.(*errorTranslatingReceiver).Receive',
              'github.com/bufbuild/connect-go.(*connectUnaryHandlerReceiver).Receive',
              'github.com/bufbuild/connect-go.(*connectUnaryUnmarshaler).UnmarshalFunc',
              'bytes.(*Buffer).ReadFrom',
              'github.com/weaveworks/common/middleware.(*reqBody).Read',
              'net/http.(*body).Read',
              'net/http.(*body).readLocked',
              'net/http/internal.(*chunkedReader).Read',
              'bufio.(*Reader).Read',
              'net/http.(*connReader).Read',
              'net.(*conn).Read',
              'net.(*netFD).Read',
              'syscall.Read',
              'syscall.read',
              'syscall.Syscall',
              'net/http.(*ServeMux).ServeHTTP',
              'net/http/pprof.Index',
              'net/http/pprof.handler.ServeHTTP',
              'runtime/pprof.(*Profile).WriteTo',
              'runtime/pprof.writeAlloc',
              'runtime/pprof.writeHeapInternal',
              'runtime/pprof.writeHeapProto',
              'runtime/pprof.(*profileBuilder).pbSample',
              'runtime/pprof.(*profileBuilder).flush',
              'compress/flate.(*compressor).encSpeed',
              'compress/flate.(*huffmanBitWriter).writeBlockDynamic',
              'github.com/felixge/httpsnoop.(*rw).Write',
              'github.com/felixge/httpsnoop.(*Metrics).CaptureMetrics.func2.1',
              'github.com/weaveworks/common/middleware.(*badResponseLoggingWriter).Write',
              'github.com/opentracing-contrib/go-stdlib/nethttp.(*statusCodeTracker).Write',
              'net/http.(*response).Write',
              'net/http.(*response).write',
              'bufio.(*Writer).Write',
              'bufio.(*Writer).Flush',
              'net/http.(*chunkWriter).Write',
              'net/http.checkConnErrorWriter.Write',
              'net.(*conn).Write',
              'net.(*netFD).Write',
              'internal/poll.(*FD).Write',
              'syscall.write',
              'compress/flate.token.length',
              'compress/flate.(*deflateFast).encode',
              'compress/flate.(*compressor).init',
              'runtime/pprof.writeHeapProto.func1',
              'runtime/pprof.(*profileBuilder).pbLabel',
              'runtime/pprof.(*protobuf).int64Opt',
              'runtime/pprof.(*protobuf).endMessage',
              'runtime/pprof.(*protobuf).startMessage',
              'runtime/pprof.(*protobuf).uint64s',
              'runtime/pprof.(*protobuf).int64s',
              'runtime/pprof.(*profileBuilder).appendLocsForStack',
              'runtime/pprof.(*profileBuilder).emitLocation',
              'runtime.memclrNoHeapPointersChunked',
              'runtime.mapassign_faststr',
              'runtime.mapaccess1_faststr',
              'runtime.memclrHasPointers',
              'runtime/pprof.(*profileBuilder).pbLine',
              'runtime.duffcopy',
              'runtime.duffzero',
              'runtime.CallersFrames',
              'runtime/pprof.allFrames',
              'runtime.funcInfo.entry',
              'runtime.(*moduledata).textAddr',
              'runtime.funcfile',
              'runtime.findnull',
              'indexbytebody',
              'runtime.funcname',
              'runtime.(*Func).Entry',
              'runtime.pcdatavalue1',
              'runtime/pprof.runtime_expandFinalInlineFrame',
              'runtime/pprof.(*profileBuilder).stringIndex',
              'runtime.growWork_faststr',
              'runtime.evacuate_faststr',
              'runtime.advanceEvacuationMark',
              'runtime/pprof.(*protobuf).uint64Opt',
              'runtime.funcdata',
              'runtime/pprof.(*profileBuilder).build',
              'compress/flate.(*deflateFast).matchLen',
              'compress/flate.(*huffmanBitWriter).writeBits',
              'compress/flate.(*huffmanBitWriter).writeDynamicHeader',
              'compress/flate.hash',
              'fmt.Fprintf',
              'fmt.(*pp).doPrintf',
              'fmt.(*pp).printArg',
              'fmt.(*pp).fmtInteger',
              'fmt.(*fmt).fmtInteger',
              'compress/flate.token.offset',
              'compress/flate.load64',
              'runtime.FuncForPC',
              'runtime/pprof.newProfileBuilder',
              'runtime/pprof.(*profileBuilder).readMapping',
              'os.ReadFile',
              'runtime.(*Func).Name',
              'runtime/pprof.scaleHeapSample',
              'math.archExp',
              'runtime.(*MemProfileRecord).Stack',
              'runtime/pprof.writeGoroutine',
              'runtime/pprof.writeRuntimeProfile',
              'runtime/pprof.runtime_goroutineProfileWithLabels',
              'runtime.goroutineProfileWithLabels',
              'runtime.forEachGRace',
              'runtime.goroutineProfileWithLabels.func4',
              'runtime.goroutineProfileWithLabels.func4.1',
              'runtime.saveg',
              'runtime.funcspdelta',
              'runtime.goroutineProfileWithLabels.func1',
              'runtime.isSystemGoroutine',
              'runtime.goroutineProfileWithLabels.func2',
              'runtime.startTheWorld',
              'runtime.startTheWorld.func1',
              'runtime.startTheWorldWithSema',
              'runtime.wakep',
              'runtime.startm',
              'runtime.notewakeup',
              'runtime.futexwakeup',
              'runtime.futex',
              'runtime.goroutineProfileWithLabels.func3',
              'runtime/pprof.printCountProfile',
              'runtime/pprof.printCountProfile.func1',
              'bytes.(*Buffer).Write',
              'runtime.slicebytetostring',
              'runtime.overLoadFactor',
              'runtime.heapBits.bits',
              'runtime.greyobject',
              'runtime/pprof.parseProcSelfMaps',
              'runtime/pprof.elfBuildID',
              'os.OpenFile',
              'syscall.Open',
              'syscall.openat',
              'syscall.Syscall6',
              'runtime/pprof.parseProcSelfMaps.func1',
              'bytes.Cut',
              'runtime/pprof.(*profileBuilder).addMapping',
              'os.Open',
              'os.openFileNolog',
              'os.newFile',
              'runtime.SetFinalizer',
              'runtime.SetFinalizer.func2',
              'runtime.addfinalizer',
              'runtime.addspecial',
              'runtime.(*pageAlloc).allocToCache',
              'os.(*File).Stat',
              'internal/poll.(*FD).Fstat',
              'syscall.Fstat',
              'os.(*File).Read',
              'runtime/pprof.(*keysByCount).Less',
              'cmpbody',
              'github.com/prometheus/client_golang/prometheus.(*GaugeVec).WithLabelValues',
              'runtime.assertI2I',
              'runtime.getitab',
              'runtime.(*itabTableType).find',
              'github.com/weaveworks/common/middleware.Instrument.getRouteName',
              'github.com/weaveworks/common/middleware.getRouteName',
              'github.com/weaveworks/common/middleware.MakeLabelValue',
              'strings.Trim',
              'github.com/gorilla/mux.(*Router).Match',
              'github.com/gorilla/mux.(*Route).Match',
              'github.com/gorilla/mux.(*routeRegexp).Match',
              'regexp.(*Regexp).doExecute',
              'regexp.(*Regexp).backtrack',
              'regexp.(*Regexp).tryBacktrack',
              'github.com/weaveworks/common/logging.gokit.Debugf',
              'github.com/uber/jaeger-client-go.(*Tracer).Extract',
              'github.com/uber/jaeger-client-go.(*TextMapPropagator).Extract',
              'runtime.assertE2I2',
              'github.com/weaveworks/common/middleware.Tracer.Wrap.func1',
              'net/http.(*response).finishRequest',
              'net/http.(*chunkWriter).writeHeader',
              'net/http.Header.writeSubset',
              'net/http.Header.sortedKeyValues',
              'sync.(*Pool).Get',
              'net/http.(*conn).readRequest',
              'runtime.mapiterinit',
              'runtime.gcBgMarkWorker',
              'runtime.gcBgMarkWorker.func2',
              'runtime.gcDrain',
              'runtime.(*gcBits).bytep',
              'runtime.(*mspan).objIndex',
              'runtime.(*gcWork).put',
              'runtime.getempty',
              'runtime.putfull',
              'runtime.(*lfstack).push',
              'runtime.(*mspan).markBitsForIndex',
              'runtime.markBits.isMarked',
              'runtime.arenaIndex',
              'runtime.(*gcWork).tryGet',
              'runtime.trygetfull',
              'runtime.spanOfUnchecked',
              'runtime.heapBits.next',
              'runtime.gcFlushBgCredit',
              'runtime.(*gcWork).balance',
              'runtime.handoff',
              'runtime.(*gcWork).tryGetFast',
              'runtime.gcMarkDone',
              'runtime.gcMarkTermination',
              'runtime.mProf_Flush',
              'runtime.mProf_FlushLocked',
              'runtime.(*bucket).mp',
              'runtime.gcMarkTermination.func4',
              'runtime.forEachP',
              'runtime.gcMarkTermination.func4.1',
              'runtime.(*mcache).prepareForSweep',
              'runtime.(*mcache).releaseAll',
              'net/http.(*persistConn).readLoop',
              'runtime.selectgo',
              'runtime.send',
              'runtime.goready',
              'runtime.goready.func1',
              'runtime.ready',
              'runtime.runqput',
              'bufio.(*Reader).Peek',
              'bufio.(*Reader).fill',
              'net/http.(*persistConn).Read',
              'net/http.(*persistConn).readResponse',
              'github.com/opentracing-contrib/go-stdlib/nethttp.(*Tracer).gotFirstResponseByte',
              'runtime.bgsweep',
              'runtime.sweepone',
              'runtime.(*sweepLocker).tryAcquire',
              'runtime.(*sweepLocked).sweep',
              'runtime.(*gcBitsArena).tryAlloc',
              'runtime.spanClass.sizeclass',
              'runtime/internal/atomic.(*Uint32).CompareAndSwap',
              'runtime.mcall',
              'runtime.park_m',
              'runtime.schedule',
              'runtime.findrunnable',
              'runtime.netpoll',
              'runtime.epollwait',
              'runtime.stopm',
              'runtime.notesleep',
              'runtime.futexsleep',
              'runtime.(*randomOrder).start',
              'runtime.checkTimers',
              'runtime.runtimer',
              'runtime.dodeltimer0',
              'runtime.gcstopm',
              'runtime.lock2',
              'runtime.procyield',
              'runtime.(*lfstack).pop',
              'runtime.gosched_m',
              'runtime.lock',
              'runtime.goschedImpl',
              'runtime.execute',
              'runtime.casgstatus',
              'net/http.(*persistConn).writeLoop',
              'net/http.(*Request).write',
              'net/http.newTransferWriter',
              'runtime.convI2I',
              'runtime.chansend1',
              'runtime.chansend',
              'net/http.persistConnWriter.Write',
              'runtime.exitsyscall',
              'runtime.exitsyscallfast',
              'runtime.exitsyscallfast_reacquired',
              'test/pkg/distributor.(*Distributor).Push.func1',
              'test/pkg/distributor.(*Distributor).sendProfiles',
              'test/pkg/distributor.(*Distributor).sendProfilesErr',
              'test/pkg/gen/ingester/v1/ingesterv1connect.(*ingesterServiceClient).Push',
              'github.com/bufbuild/connect-go.(*Client[...]).CallUnary',
              'github.com/bufbuild/connect-go.NewClient[...].func2',
              'github.com/bufbuild/connect-go.NewClient[...].func1',
              'github.com/bufbuild/connect-go.receiveUnaryResponse[...]',
              'github.com/bufbuild/connect-go.(*connectUnaryClientReceiver).Receive',
              'github.com/bufbuild/connect-go.(*compressionPool).Decompress',
              'github.com/bufbuild/connect-go.(*compressionPool).getDecompressor',
              'compress/gzip.(*Reader).Reset',
              'compress/gzip.(*Reader).readHeader',
              'compress/flate.(*dictDecoder).init',
              'runtime.heapBits.initSpan',
              'compress/flate.(*decompressor).Reset',
              'runtime.newstack',
              'runtime.copystack',
              'compress/flate.NewReader',
              'compress/flate.fixedHuffmanDecoderInit',
              'github.com/bufbuild/connect-go.(*connectClientSender).Send',
              'github.com/bufbuild/connect-go.(*connectUnaryMarshaler).write',
              'github.com/bufbuild/connect-go.(*duplexHTTPCall).Write',
              'io.(*pipe).write',
              'runtime.chanrecv1',
              'runtime.chanrecv',
              'runtime.recv',
              'github.com/bufbuild/connect-go.(*errorTranslatingReceiver).Close',
              'github.com/bufbuild/connect-go.(*connectUnaryClientReceiver).Close',
              'github.com/bufbuild/connect-go.(*duplexHTTPCall).CloseRead',
              'github.com/opentracing-contrib/go-stdlib/nethttp.closeTracker.Close',
              'runtime/pprof.profileWriter',
              'runtime/pprof.(*profileBuilder).addCPUData',
              'runtime/pprof.(*profMap).lookup',
              'net/http.(*connReader).backgroundRead',
              'internal/poll.(*FD).Read',
            ],
          },
        },
      },
    },
  ],
};

export const flameGraphDataDiff: DataFrameDTO = {
  name: 'response',
  meta: { preferredVisualisationType: 'flamegraph' },
  fields: [
    {
      name: 'level',
      values: [
        0, 1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6, 6, 7, 8, 9, 10, 9, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 20, 21,
        19, 20, 21, 19, 17, 18, 19, 20, 20, 21, 19, 20, 21, 19, 17, 18, 19, 20, 21, 22, 23, 24, 25, 23, 24, 21, 19, 20,
        21, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 22, 15, 16, 17, 18, 19,
        20, 17, 18, 19, 20, 21, 22, 23, 23, 24, 24, 25, 21, 17, 18, 19, 19, 17, 18, 19, 20, 16, 17, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 22, 22, 22, 22, 23, 20, 20, 16, 17, 18, 19, 16, 17, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 17, 18, 19, 20, 21, 22, 23, 15, 16, 15, 16, 12, 13, 9, 9, 10, 9, 10, 6, 6, 6, 6, 7, 8, 6, 7, 2, 3, 4, 5, 6,
        7, 3, 4, 5, 6, 7, 8, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 10, 11, 12, 13, 14, 15, 16, 16, 17, 18, 19, 20, 21, 22,
        14, 15, 16, 14, 11, 12, 13, 14, 9, 10, 11, 12, 10, 9, 10, 11, 12, 13, 14, 15, 16, 17, 15, 16, 17, 16, 15, 16,
        17, 16, 17, 16, 15, 16, 17, 18, 19, 20, 21, 19, 4, 5, 6, 7, 8, 9, 6, 7, 8, 6, 7, 8, 9, 10, 7, 4, 5, 6, 7, 8, 3,
        4, 5, 6, 7, 8, 9, 10, 11, 12, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 6,
        7, 8, 9, 10, 11, 7, 8, 6, 7, 8, 9, 10, 11, 12, 13, 6, 7, 8, 9, 10, 11, 12, 13, 14, 6, 7, 5, 6, 7, 8, 9, 10, 11,
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 3, 4, 5, 6, 1, 2,
      ],
    },
    {
      name: 'label',
      values: [
        'total',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!Heartbeat.<>c.<.ctor>b__8_0',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!Heartbeat.TimerLoop',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!Heartbeat.OnHeartbeat',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!DateHeaderValueManager.SetDateValues',
        'Microsoft.Net.Http.Headers!HeaderUtilities.FormatDate',
        'System!DateTimeFormat.Format',
        'System.Threading!PortableThreadPool.WorkerThread.WorkerThreadStart',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection<T>.System.Threading.IThreadPoolWorkItem.Execute',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection<T>.ExecuteAsync',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<ExecuteAsync>d__6>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection.<ExecuteAsync>d__6<T>.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnection..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnection.ProcessRequestsAsync<!T0>',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<ProcessRequestsAsync>d__12>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnection.<ProcessRequestsAsync>d__12<TContext>.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1OutputProducer..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.ProcessRequestsAsync<!T0>',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<ProcessRequestsAsync>d__222>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.<ProcessRequestsAsync>d__222<TContext>.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.ProcessRequests<!T0>',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<ProcessRequests>d__223>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.<ProcessRequests>d__223<TContext>.MoveNext',
        'Microsoft.AspNetCore.HostFiltering!HostFilteringMiddleware.Invoke',
        'Microsoft.AspNetCore.Routing!EndpointMiddleware.Invoke',
        'Example!Program.<>c__DisplayClass0_0.<Main>b__0',
        'Example!BikeService.Order',
        'Example!OrderService.FindNearestVehicle',
        'Pyroscope!LabelSet.BuildUpon',
        'Pyroscope!LabelsWrapper.Do',
        'Pyroscope!Profiler.get_Instance',
        'Pyroscope!LabelSet.Builder.Add',
        'System.Collections.Generic!Dictionary<TKey, TKey>.TryInsert',
        'System.Collections.Generic!Dictionary<TKey, TKey>.Initialize',
        'Pyroscope!LabelSet.Builder.Build',
        'Example!Program.<>c__DisplayClass0_0.<Main>b__1',
        'Example!ScooterService.Order',
        'Example!OrderService.FindNearestVehicle',
        'Pyroscope!LabelSet.BuildUpon',
        'Pyroscope!LabelsWrapper.Do',
        'Pyroscope!Profiler.get_Instance',
        'Pyroscope!LabelSet.Builder.Add',
        'System.Collections.Generic!Dictionary<TKey, TKey>.TryInsert',
        'System.Collections.Generic!Dictionary<TKey, TKey>.Initialize',
        'Pyroscope!LabelSet.Builder.Build',
        'Example!Program.<>c__DisplayClass0_0.<Main>b__2',
        'Example!CarService.Order',
        'Example!OrderService.FindNearestVehicle',
        'Pyroscope!LabelsWrapper.Do',
        'Example!OrderService.<>c__DisplayClass0_1.<FindNearestVehicle>b__0',
        'Example!OrderService.CheckDriverAvailability',
        'Pyroscope!LabelSet.BuildUpon',
        'System.Collections.Generic!Dictionary<TKey, TKey>..ctor',
        'System.Collections.Generic!Dictionary<TKey, TKey>.Initialize',
        'Pyroscope!LabelsWrapper.Do',
        'Pyroscope!Profiler.get_Instance',
        'Pyroscope!Profiler.get_Instance',
        'Pyroscope!LabelSet.Builder.Add',
        'System.Collections.Generic!Dictionary<TKey, TKey>.TryInsert',
        'System.Collections.Generic!Dictionary<TKey, TKey>.Initialize',
        'Microsoft.AspNetCore.Http!RequestDelegateFactory.ExecuteWriteStringResponseAsync',
        'Microsoft.AspNetCore.Http!HttpResponseWritingExtensions.WriteAsync',
        'Microsoft.AspNetCore.Http!HttpResponseWritingExtensions.WriteAsync',
        'Microsoft.AspNetCore.Http!DefaultHttpResponse.StartAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.Microsoft.AspNetCore.Http.Features.IHttpResponseBodyFeature.StartAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1OutputProducer.WriteResponseHeaders',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure.PipeWriterHelpers!ConcurrentPipeWriter.GetSpan',
        'System.IO.Pipelines!Pipe.DefaultPipeWriter.GetSpan',
        'System.IO.Pipelines!Pipe.AllocateWriteHeadSynchronized',
        'System.IO.Pipelines!Pipe.CreateSegmentUnsynchronized',
        'Microsoft.Extensions.Logging!LoggerMessage.<>c__DisplayClass10_0<T1>.<Define>g__Log|0',
        'Microsoft.Extensions.Logging!Logger<T>.Microsoft.Extensions.Logging.ILogger.Log<!LogValues>',
        'Microsoft.Extensions.Logging!Logger.Log<!LogValues>',
        'Microsoft.Extensions.Logging!Logger.<Log>g__LoggerLog|12_0<!LogValues>',
        'Microsoft.Extensions.Logging.Console!ConsoleLogger.Log<!LogValues>',
        'Microsoft.Extensions.Logging.Console!SimpleConsoleFormatter.Write<!LogValues>',
        'Microsoft.Extensions.Logging!LoggerMessage.LogValues.<>c<T0>.<.cctor>b__12_0',
        'Microsoft.Extensions.Logging!LoggerMessage.LogValues<T0>.ToString',
        'System!String.FormatHelper',
        'System!Span<System!Char>.ToString',
        'System!String.Ctor',
        'System.Text!StringBuilder.ToString',
        'Microsoft.AspNetCore.Hosting!HostingApplication.CreateContext',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.BeginRequest',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.Log.RequestScope',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.Log.HostingLogScope..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.Microsoft.AspNetCore.Http.Features.IHttpRequestIdentifierFeature.get_TraceIdentifier',
        'System!String.Create<System!ValueTuple>',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.LogRequestStarting',
        'Microsoft.Extensions.Logging!Logger.Log<!T0>',
        'Microsoft.Extensions.Logging!Logger.<Log>g__LoggerLog|12_0<!T0>',
        'Microsoft.Extensions.Logging.Console!ConsoleLogger.Log<!T0>',
        'Microsoft.Extensions.Logging.Console!SimpleConsoleFormatter.Write<!T0>',
        'Microsoft.AspNetCore.Hosting!HostingRequestStartingLog.ToString',
        'System.Buffers!TlsOverPerCoreLockedStacksArrayPool<System!Char>.Rent',
        'System.Runtime.CompilerServices!DefaultInterpolatedStringHandler.ToStringAndClear',
        'System!String.Ctor',
        'System.Buffers!TlsOverPerCoreLockedStacksArrayPool<System!Char>.Return',
        'System.Buffers!TlsOverPerCoreLockedStacksArrayPool<System!Char>.InitializeTlsBucketsAndTrimming',
        'System.Text!StringBuilder.ToString',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.StartActivity',
        'System.Diagnostics!Activity.Start',
        'System!String.Create<System!ValueTuple>',
        'System.Threading!ExecutionContext.SetLocalValue',
        'Microsoft.Extensions.Logging!Logger.BeginScope<!T0>',
        'Microsoft.Extensions.Logging!LoggerFactoryScopeProvider.Push',
        'System.Threading!ExecutionContext.SetLocalValue',
        'System.Threading!AsyncLocalValueMap.TwoElementAsyncLocalValueMap.Set',
        'Microsoft.AspNetCore.Http!DefaultHttpContextFactory.Create',
        'Microsoft.AspNetCore.Http!DefaultHttpContext..ctor',
        'Microsoft.AspNetCore.Hosting!HostingApplication.DisposeContext',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.LogRequestFinished',
        'Microsoft.Extensions.Logging!Logger.Log<!T0>',
        'Microsoft.Extensions.Logging!Logger.<Log>g__LoggerLog|12_0<!T0>',
        'Microsoft.Extensions.Logging.Console!ConsoleLogger.Log<!T0>',
        'Microsoft.Extensions.Logging.Console!SimpleConsoleFormatter.Write<!T0>',
        'Microsoft.AspNetCore.Hosting!HostingRequestFinishedLog.ToString',
        'System!Number.FormatDouble',
        'System.Text!ValueStringBuilder.ToString',
        'System!Span<System!Char>.ToString',
        'System!String.Ctor',
        'System!Number.UInt32ToDecStr',
        'System!String.Replace',
        'System!String.Substring',
        'System.Runtime.CompilerServices!DefaultInterpolatedStringHandler.ToStringAndClear',
        'System!String.Ctor',
        'System.Text!StringBuilder.ToString',
        'System.Text!StringBuilder.set_Length',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.StopActivity',
        'System.Diagnostics!Activity.Stop',
        'System.Threading!ExecutionContext.SetLocalValue',
        'System.Threading!AsyncLocalValueMap.TwoElementAsyncLocalValueMap.Set',
        'Microsoft.Extensions.Logging!LoggerFactoryScopeProvider.Scope.Dispose',
        'System.Threading!ExecutionContext.SetLocalValue',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.TryParseRequest',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.ParseRequest',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.TakeMessageHeaders',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.ParseHeaders',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.TryTakeSingleHeader',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.OnHeader',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpRequestHeaders.Append',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!HttpUtilities.GetRequestHeaderString',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!StringUtilities.GetAsciiOrUTF8StringNonNullCharacters',
        'System!String.Create<System!IntPtr>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.TakeStartLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.ParseRequestLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.ParseRequestLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.OnStartLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.OnOriginFormTarget',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!StringUtilities.GetAsciiStringNonNullCharacters',
        'System!String.Create<System!IntPtr>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.InitializeBodyControl',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!BodyControl..ctor',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.AwaitUnsafeOnCompleted<System.Runtime.CompilerServices!ValueTaskAwaiter, !<ProcessRequests>d__223>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<ProcessRequests>d__223>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.AwaitUnsafeOnCompleted<System.Runtime.CompilerServices!TaskAwaiter, !<ProcessRequestsAsync>d__222>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<ProcessRequestsAsync>d__222>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection.OnHeartbeat',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.AwaitUnsafeOnCompleted<System.Runtime.CompilerServices!TaskAwaiter, !<ProcessRequestsAsync>d__12>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<ProcessRequestsAsync>d__12>',
        'System.Threading!CancellationToken.Register',
        'System.Threading!CancellationTokenSource.Register',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnectionMiddleware<TContext>.OnConnectionAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection.BeginConnectionScope',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!TimeoutControl..ctor',
        'Microsoft.Extensions.Logging!Logger.BeginScope<!T0>',
        'Microsoft.Extensions.Logging!LoggerFactoryScopeProvider.Push',
        'System.Threading!ExecutionContext.SetLocalValue',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.AwaitUnsafeOnCompleted<System.Runtime.CompilerServices!TaskAwaiter, !<ExecuteAsync>d__6>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<ExecuteAsync>d__6>',
        'System.Threading!ThreadPoolWorkQueue.Dispatch',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!IOQueue.System.Threading.IThreadPoolWorkItem.Execute',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<System.Threading.Tasks!VoidTaskResult, Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoSend>d__28>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoSend>d__28.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.Shutdown',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<>c.<FireConnectionClosed>b__29_0',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.CancelConnectionClosedToken',
        'System.Threading!CancellationTokenSource.ExecuteCallbackHandlers',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnection.OnConnectionClosed',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.OnInputOrOutputCompleted',
        'System.Net.Sockets!SocketAsyncEngine.System.Threading.IThreadPoolWorkItem.Execute',
        'System.Net.Sockets!Socket.AwaitableSocketAsyncEventArgs.OnCompleted',
        'System.Net.Sockets!Socket.AwaitableSocketAsyncEventArgs.InvokeContinuation',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets!SocketConnectionListener.<AcceptAsync>d__10.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets!SocketConnectionContextFactory.Create',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection..ctor',
        'System.IO.Pipelines!DuplexPipe.CreateConnectionPair',
        'System.IO.Pipelines!Pipe..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.Start',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.DoReceive',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<DoReceive>d__27>',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoReceive>d__27.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketReceiver.WaitForDataAsync',
        'System.Net.Sockets!Socket.ReceiveAsync',
        'System.Net.Sockets!SocketAsyncContext..ctor',
        'System.Net.Sockets!SocketAsyncEventArgs.DoOperationReceive',
        'System.Net.Sockets!SocketAsyncContext.ReceiveAsync',
        'System.Net.Sockets!SocketAsyncContext.OperationQueue<TOperation>.StartAsyncOperation',
        'System.Net.Sockets!SocketAsyncContext.TryRegister',
        'System.Net.Sockets!SocketAsyncEngine.TryRegisterSocket',
        'System.Net.Sockets!SocketAsyncEngine.TryRegisterCore',
        'System.Collections.Concurrent!ConcurrentDictionary<System!IntPtr, System.Net.Sockets!SocketAsyncEngine.SocketAsyncContextWrapper>.TryAddInternal',
        'System.IO.Pipelines!Pipe.GetMemory',
        'System.IO.Pipelines!Pipe.AllocateWriteHeadSynchronized',
        'System.IO.Pipelines!Pipe.CreateSegmentUnsynchronized',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<DoReceive>d__27>',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.DoSend',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<DoSend>d__28>',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoSend>d__28.MoveNext',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<DoSend>d__28>',
        'System.Net.Sockets!Socket.get_LocalEndPoint',
        'System.Net.Sockets!IPEndPointExtensions.Create',
        'System.Net.Internals!SocketAddress.GetIPEndPoint',
        'System.Net.Internals!SocketAddress.GetIPAddress',
        'System.Net.Sockets!IPEndPointExtensions.Serialize',
        'System.Threading.Tasks!Task<TResult>.TrySetResult',
        'System.Threading.Tasks!Task.RunContinuations',
        'System.Threading.Tasks!AwaitTaskContinuation.RunOrScheduleAction',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!ConnectionDispatcher.<>c__DisplayClass8_0.<<StartAcceptingConnectionsCore>g__AcceptConnectionsAsync|0>d<T>.MoveNext',
        'Microsoft.AspNetCore.Connections!TransportConnection.Microsoft.AspNetCore.Http.Features.IFeatureCollection.Set<!T0>',
        'System.Collections.Generic!List<T>.AddWithResize',
        'System.Collections.Generic!List<T>.set_Capacity',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection<T>..ctor',
        'Microsoft.AspNetCore.Connections!TransportConnection.Microsoft.AspNetCore.Http.Features.IFeatureCollection.Set<!T0>',
        'Microsoft.AspNetCore.Connections!TransportConnection.ExtraFeatureSet',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!TransportConnectionManager.AddConnection',
        'Microsoft.AspNetCore.Connections!TransportConnection.get_ConnectionId',
        'System!String.Create<System!Int64>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!ConnectionManager.AddConnection',
        'System.Collections.Concurrent!ConcurrentDictionary<TKey, TKey>.TryAddInternal',
        'System.Collections.Concurrent!ConcurrentDictionary<TKey, TKey>.TryAddInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!TransportManager.GenericConnectionListener.AcceptAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets!SocketConnectionListener.AcceptAsync',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<AcceptAsync>d__10>',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets!SocketConnectionListener.<AcceptAsync>d__10.MoveNext',
        'System.Net.Sockets!Socket.AwaitableSocketAsyncEventArgs.AcceptAsync',
        'System.Net.Sockets!Socket.AcceptAsync',
        'System.Net.Sockets!SocketAsyncEventArgs.DoOperationAccept',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<TResult>.GetStateMachineBox<!<AcceptAsync>d__10>',
        'System.Net.Sockets!SocketAsyncEventArgs.AcceptCompletionCallback',
        'System.Net.Sockets!SocketAsyncEventArgs.FinishOperationSyncSuccess',
        'System.Net.Sockets!IPEndPointExtensions.Create',
        'System.Net.Internals!SocketAddress.GetIPEndPoint',
        'System.Net.Internals!SocketAddress.GetIPAddress',
        'System.Net!IPAddress..ctor',
        'System.Net.Sockets!IPEndPointExtensions.Serialize',
        'System.Net.Internals!SocketAddress..ctor',
        'System.Net.Internals!SocketAddress..ctor',
        'System.Net.Sockets!SocketAsyncEventArgs.FinishOperationAccept',
        'System.Net.Sockets!IPEndPointExtensions.Create',
        'System.Net.Internals!SocketAddress.GetIPEndPoint',
        'System.Net.Internals!SocketAddress.GetIPAddress',
        'System.Net!IPAddress..ctor',
        'System.Net.Sockets!SocketPal.CreateSocket',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<System.Threading.Tasks!VoidTaskResult, Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoReceive>d__27>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DoReceive>d__27.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.FireConnectionClosed',
        'System.Threading!ThreadPool.UnsafeQueueUserWorkItem<!T0>',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunFromThreadPoolDispatchLoop',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.<ProcessRequests>d__223<TContext>.MoveNext',
        'Microsoft.AspNetCore.HostFiltering!HostFilteringMiddleware.Invoke',
        'Microsoft.AspNetCore.Routing!EndpointMiddleware.Invoke',
        'Example!Program.<>c__DisplayClass0_0.<Main>b__0',
        'Example!BikeService.Order',
        'Example!OrderService.FindNearestVehicle',
        'Pyroscope!LabelsWrapper.Do',
        'Pyroscope!Profiler.get_Instance',
        'Microsoft.AspNetCore.Http!RequestDelegateFactory.ExecuteWriteStringResponseAsync',
        'Microsoft.AspNetCore.Http!HttpResponseWritingExtensions.WriteAsync',
        'Microsoft.AspNetCore.Http!HttpResponseWritingExtensions.WriteAsync',
        'Microsoft.AspNetCore.Http!DefaultHttpResponse.StartAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.Microsoft.AspNetCore.Http.Features.IHttpResponseBodyFeature.StartAsync',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1OutputProducer.WriteResponseHeaders',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure.PipeWriterHelpers!ConcurrentPipeWriter.GetSpan',
        'System.IO.Pipelines!Pipe.DefaultPipeWriter.GetSpan',
        'System.IO.Pipelines!Pipe.AllocateWriteHeadSynchronized',
        'System.IO.Pipelines!Pipe.CreateSegmentUnsynchronized',
        'Microsoft.Extensions.Logging!LoggerMessage.<>c__DisplayClass10_0<T1>.<Define>g__Log|0',
        'Microsoft.Extensions.Logging!Logger<T>.Microsoft.Extensions.Logging.ILogger.Log<!LogValues>',
        'Microsoft.Extensions.Logging!Logger.Log<!LogValues>',
        'Microsoft.Extensions.Logging!Logger.<Log>g__LoggerLog|12_0<!LogValues>',
        'Microsoft.Extensions.Logging.Console!ConsoleLogger.Log<!LogValues>',
        'Microsoft.Extensions.Logging.Console!SimpleConsoleFormatter.Write<!LogValues>',
        'Microsoft.Extensions.Logging!LoggerMessage.LogValues.<>c<T0>.<.cctor>b__12_0',
        'Microsoft.Extensions.Logging!LoggerMessage.LogValues<T0>.ToString',
        'System!String.FormatHelper',
        'System!Span<System!Char>.ToString',
        'System!String.Ctor',
        'Microsoft.AspNetCore.Hosting!HostingApplication.CreateContext',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.BeginRequest',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.Log.RequestScope',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.Log.HostingLogScope..ctor',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.Microsoft.AspNetCore.Http.Features.IHttpRequestIdentifierFeature.get_TraceIdentifier',
        'System!String.Create<System!ValueTuple>',
        'Microsoft.AspNetCore.Http!DefaultHttpContextFactory.Create',
        'Microsoft.AspNetCore.Http!DefaultHttpContext..ctor',
        'Microsoft.AspNetCore.Hosting!HostingApplication.DisposeContext',
        'Microsoft.AspNetCore.Hosting!HostingApplicationDiagnostics.LogRequestFinished',
        'Microsoft.Extensions.Logging!Logger.Log<!T0>',
        'Microsoft.Extensions.Logging!Logger.<Log>g__LoggerLog|12_0<!T0>',
        'Microsoft.Extensions.Logging.Console!ConsoleLogger.Log<!T0>',
        'Microsoft.Extensions.Logging.Console!SimpleConsoleFormatter.Write<!T0>',
        'Microsoft.AspNetCore.Hosting!HostingRequestFinishedLog.ToString',
        'System!Number.UInt32ToDecStr',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.TryParseRequest',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.ParseRequest',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.TakeStartLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.ParseRequestLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpParser<Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1ParsingHandler>.ParseRequestLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.OnStartLine',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!Http1Connection.OnOriginFormTarget',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!StringUtilities.GetAsciiStringNonNullCharacters',
        'System!String.Create<System!IntPtr>',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.InitializeBodyControl',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!BodyControl..ctor',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.SetResult',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.SetExistingTaskResult',
        'System.Threading.Tasks!Task.RunContinuations',
        'System.Threading.Tasks!AwaitTaskContinuation.RunOrScheduleAction',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Http!HttpProtocol.<ProcessRequestsAsync>d__222<TContext>.MoveNext',
        'System.Threading.Tasks!Task<System.Threading.Tasks!VoidTaskResult>.TrySetResult',
        'System.Threading.Tasks!Task.RunContinuations',
        'System.Threading.Tasks!AwaitTaskContinuation.RunOrScheduleAction',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal!HttpConnection.<ProcessRequestsAsync>d__12<TContext>.MoveNext',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.SetExistingTaskResult',
        'System.Threading.Tasks!Task.RunContinuations',
        'System.Threading.Tasks!AwaitTaskContinuation.RunOrScheduleAction',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder.AsyncStateMachineBox<TResult, TResult>.MoveNext',
        'System.Threading!ExecutionContext.RunInternal',
        'Microsoft.AspNetCore.Server.Kestrel.Core.Internal.Infrastructure!KestrelConnection.<ExecuteAsync>d__6<T>.MoveNext',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.DisposeAsync',
        'System.Runtime.CompilerServices!AsyncMethodBuilderCore.Start<!<DisposeAsync>d__26>',
        'Microsoft.AspNetCore.Server.Kestrel.Transport.Sockets.Internal!SocketConnection.<DisposeAsync>d__26.MoveNext',
        'System.Runtime.CompilerServices!AsyncTaskMethodBuilder<System.Threading.Tasks!VoidTaskResult>.GetStateMachineBox<!<DisposeAsync>d__26>',
        'System.Threading!UnmanagedThreadPoolWorkItem.System.Threading.IThreadPoolWorkItem.Execute',
        'System.Threading!TimerQueue.FireNextTimers',
        'System.Threading!TimerQueueTimer.Fire',
        'Microsoft.Extensions.FileProviders.Physical!PhysicalFilesWatcher.RaiseChangeEvents',
        'System.Threading!ThreadPoolWorkQueueThreadLocals.Finalize',
        'System.Threading!ThreadPoolWorkQueue.WorkStealingQueueList.Remove',
      ],
    },
    {
      name: 'self',
      values: [
        0, 0, 0, 0, 0, 0, 12, 4, 0, 0, 0, 0, 8, 0, 0, 17, 3, 12, 26, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1,
        0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 0, 2, 0, 0, 1, 0, 0, 0, 0, 3, 1, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 7, 15, 2, 0, 0, 0, 0, 3, 1, 0, 0, 0, 0, 0, 0, 0, 4, 0, 1, 8, 7, 0, 1, 3, 2, 1, 0, 1,
        3, 5, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 4, 0, 5, 12, 1, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0,
        0, 0, 0, 0, 0, 0, 0, 10, 0, 1, 0, 3, 2, 0, 0, 0, 7, 7, 1, 1, 0, 0, 6, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3,
        0, 0, 0, 0, 0, 0, 15, 14, 14, 4, 0, 0, 0, 0, 0, 0, 3, 9, 3, 0, 0, 0, 0, 2, 0, 0, 1, 2, 0, 0, 0, 6, 0, 0, 2, 3,
        1, 0, 0, 0, 0, 0, 3, 0, 0, 3, 0, 0, 2, 5, 0, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 1, 1, 0, 5, 0, 3,
        2, 0, 1, 3, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 1, 0, 1,
      ],
    },
    {
      name: 'value',
      values: [
        378, 12, 12, 12, 12, 12, 12, 365, 230, 230, 230, 230, 8, 206, 206, 206, 15, 12, 26, 139, 139, 139, 136, 136,
        136, 42, 42, 3, 3, 2, 0, 1, 1, 1, 1, 1, 0, 4, 4, 2, 0, 1, 1, 1, 1, 1, 1, 9, 9, 7, 5, 4, 4, 0, 0, 0, 3, 3, 1, 2,
        2, 2, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 22, 22, 22, 22, 22, 7, 7, 7, 7, 7, 7, 15, 42, 32, 3, 3, 3, 3, 14, 13, 13,
        13, 5, 5, 0, 5, 4, 1, 1, 8, 11, 4, 1, 3, 4, 2, 1, 1, 8, 5, 29, 26, 25, 25, 25, 12, 12, 2, 2, 2, 2, 0, 1, 4, 5,
        5, 12, 1, 3, 3, 3, 0, 0, 0, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 0, 0, 0, 0, 0, 0, 0, 10, 10, 1, 1, 3, 3, 2,
        0, 0, 7, 7, 7, 1, 1, 6, 6, 6, 1, 1, 131, 0, 0, 0, 0, 0, 3, 3, 3, 3, 3, 3, 117, 97, 97, 97, 97, 97, 73, 32, 18,
        4, 26, 20, 20, 20, 17, 17, 3, 14, 5, 2, 2, 2, 2, 2, 1, 1, 1, 2, 6, 6, 6, 6, 6, 5, 5, 3, 1, 18, 18, 18, 18, 18,
        18, 3, 3, 3, 7, 2, 2, 5, 3, 0, 0, 2, 2, 1, 2, 2, 2, 2, 1, 1, 1, 1, 20, 20, 2, 2, 1, 0, 8, 3, 3, 10, 6, 6, 5, 2,
        2, 0, 0, 0, 0, 0, 10, 10, 7, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,
        3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 1, 1, 1, 1, 1, 1,
      ],
    },
    {
      name: 'selfRight',
      values: [
        0, 0, 0, 0, 0, 0, 16, 5, 0, 0, 0, 0, 1, 0, 0, 19, 2, 8, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1,
        1, 0, 0, 1, 1, 0, 2, 0, 0, 2, 1, 0, 0, 1, 0, 0, 0, 1, 0, 2, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 11, 1, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 0, 7, 3, 0, 3, 2, 2, 0, 1, 1,
        4, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 3, 0, 4, 13, 0, 0, 0, 0, 1, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0,
        0, 0, 0, 0, 0, 1, 1, 4, 0, 5, 0, 1, 0, 0, 1, 0, 7, 9, 3, 0, 0, 2, 2, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 4, 12, 12, 5, 0, 0, 0, 0, 0, 0, 2, 9, 0, 0, 0, 0, 0, 1, 0, 0, 4, 3, 0, 0, 0, 6, 0, 0, 1, 2, 0,
        0, 0, 0, 0, 0, 2, 0, 0, 1, 0, 0, 6, 8, 0, 0, 2, 0, 1, 1, 0, 0, 0, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 1, 0, 0, 1, 3,
        0, 2, 1, 0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
    },
    {
      name: 'valueRight',
      values: [
        316, 16, 16, 16, 16, 16, 16, 300, 190, 190, 190, 190, 1, 173, 173, 173, 10, 8, 28, 108, 108, 108, 107, 107, 107,
        34, 34, 4, 4, 2, 1, 1, 1, 1, 1, 1, 1, 7, 7, 4, 1, 2, 2, 2, 2, 2, 1, 9, 9, 9, 8, 8, 8, 3, 2, 2, 5, 5, 0, 0, 0, 0,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 13, 13, 13, 13, 13, 2, 2, 2, 2, 2, 2, 11, 31, 24, 2, 2, 2, 2, 10, 10, 10, 10, 3,
        3, 1, 2, 2, 0, 0, 7, 8, 5, 3, 2, 4, 2, 2, 1, 6, 2, 28, 24, 24, 24, 24, 11, 11, 1, 1, 1, 1, 1, 2, 3, 4, 4, 13, 0,
        1, 1, 1, 1, 3, 3, 4, 4, 3, 3, 3, 3, 3, 3, 3, 3, 1, 1, 1, 1, 1, 1, 1, 5, 4, 5, 5, 1, 1, 0, 1, 1, 7, 7, 9, 3, 0,
        4, 4, 2, 0, 0, 105, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 100, 87, 87, 87, 87, 87, 58, 29, 17, 5, 25, 19, 19, 19, 12,
        12, 2, 10, 1, 1, 1, 1, 1, 1, 4, 4, 4, 3, 6, 6, 6, 6, 3, 3, 3, 2, 0, 26, 26, 26, 26, 26, 26, 1, 1, 1, 14, 6, 6,
        8, 4, 2, 2, 1, 1, 1, 5, 5, 5, 5, 2, 2, 2, 3, 10, 10, 1, 1, 1, 1, 1, 1, 1, 8, 3, 3, 1, 0, 2, 3, 3, 3, 3, 3, 3, 3,
        3, 3, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
    },
  ],
};
